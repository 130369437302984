import { P } from 'styling/Text';
import './config';

let entities = {};
export default entities;
export {default as entities} from 'entities.js';

entities.employees = {};
entities.companies = {};

const basicText = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.";

// --- Entity Classes ---

export class Entity {
	constructor(props) {
		this.active = props.active || true;
		this.name = props.name;
		this.role = props.role;
		this.logo = props.logo || ImageSwitch(global.config.placeholder.image);// can also be a profile picture
		this.emails = props.emails;
		this.phoneNumbers = props.phoneNumbers;

		return {
			active: this.active
			,name: this.name
			,role: this.role
			,logo: this.logo
			,emails: this.emails
			,phoneNumbers: this.phoneNumbers
		};
	}
};

export class Company extends Entity{
	constructor(props) {
		super(props);
		this.kvk = props.kvk;
		this.btw = props.btw;

		// - Projects
		this.projects = [];
	

		this.GetProjectAmount = function() {
			return this.projects.length;
		}

		this.AddProject = function(_project, _owner = new InfoList()) {
			// console.log(_owner);
			if (!_owner.dataObjects) _project.owner = new InfoList(this);// If no owner is given. Set the default to this
			
			this.projects.push(_project);
		}

		// this.AddProjects = function(_projects = [], _owner = undefined) {
		// 	_projects.forEach(item => {
		// 		if (!_owner) item.owner = this;
		// 	});

		// 	this.projects.push(_projects);
		// }

		this.RemoveProject = function(_project) {
			this.projects.push(_project);
		}

		this.GetProjects = function() {
			return this.projects;
		}

		this.GetProjectByIndex = function(_index) {
			return this.projects[_index];
		}

		this.GetProjectByName = function(_name) {
			let _return = undefined;

			this.projects.forEach(item => {
				if (item.name) {
					_return = item;
					return false;
				}
			});

			return _return;
		}
	}
}

// --- Projects ---

export class Project {
	constructor(props) {
		this.owner = props.owner;
		this.name = props.name || "Example Project";
		this.description = props.description || (basicText + basicText + basicText);
		this.label = props.label;
		this.date = props.date;
		this.image = props.image;
		this.imageArray = props.imageArray;
		this.vertical = props.vertical;
		this.thumbnailsBottom = props.thumbnailsBottom;
	}
}

// --- Utilities ---AddProject
export class InfoList {
	constructor(..._dataObjects) {
		this.dataObjects = undefined;

		if (Array.isArray(_dataObjects)) {
			this.dataObjects = _dataObjects;
			// If there is only one instance
			// if (_dataObjects.length == 1) {
			// 	// this.dataObjects = [];
			// 	this.dataObjects = String(_dataObjects[0]);
			// }
			// console.log(this.dataObjects, _dataObjects.length);
		}		

		return this.dataObjects;
	}
}

// --- Companies ---
let _company;

entities.companies.SchreuderMetaalEnTechniek = new Company({
	name: 'Schreuder Metaal & Techniek'
	,logo: ImageSwitch('logo/logo_SchreuderMetaalEnTechniek_300dpi.png', 'logo/logo_SchreuderMetaalEnTechniek_White_300dpi.png')
	,emails: new InfoList('info@schreudermetaalentechniek.nl')
	,phoneNumbers: new InfoList('(+31) 228 52 66 21')
});
_company = entities.companies.SchreuderMetaalEnTechniek;
// _company.AddProject(new Project({
// 	name: "Test Project"
// 	,description: <><P>Test Discription</P></>
// 	,image: '/images/portfolio/projects/bronzen-giektop/Eindresultaat Bronzen Giektop.jpg'
// }));

// Test

_company.AddProject(new Project({
	name: "Onstekingsassen Stutz Oldtimer"
	,vertical: true
	,thumbnailsBottom: true
	,description: <P>
		<P>Voor wijlen Fred de Boer heb ik een hele mooie bijdrage mogen leveren om de motor van deze oldtimer qua ontsteking weer nieuw leven in te blazen.</P>
		<P>Hij kwam bij mij langs met de vraag of ik een vervangende ontstekingsas en tandwiel kon maken die beter was dan een exemplaar dat ooit was gemaakt en niet van de juiste materiaallegering.</P>
		<P>Het probleem was dat de overbrengingsverhouding niet klopte, de motor daardoor niet goed was af te stellen en de assen na ongeveer 50 kilometer waren opgevreten.</P>
		<P>''Kun je mij helpen, want ik wil graag de auto voor de trouwerij van mijn dochter gebruiken?'' Natuurlijk ga ik dat doen.</P>
		<P>Eerst de technische gegevens uitwerken, wat de juiste tandwielverhouding zou moeten zijn, en alles in CAD uitgetekend. Vervolgens het nodige draaiwerk en de gespiraliseerde tandvorm frezen. Alles laten harden en op passingen nabewerkt.</P>
		<P>Dit alles was net op tijd gereed om ingebouwd te worden.</P>
		<P>Proefdraaien en bij Fred een glimlach van oor tot oor. Zo mooi en betrouwbaar heeft deze 8 cilinder lijnmotor nog nooit gelopen.</P>
		<P>Je begrijpt dat de trouwdag een gouden randje gekregen heeft.</P>
		<P>Lees hier meer: <a target='blank' href='https://de-andijker.nl/familie-f-j-de-boer-in-de-prijzen-met-stutz/'>De Andijker</a></P>
	</P>
	// ,image: '/images/portfolio/projects/oudste-tram/tram_11.jpg'
	,imageArray: [
		,'/images/portfolio/projects/stutz-oldtimer/onstekingsas_4.jpg'
		,'/images/portfolio/projects/stutz-oldtimer/de-andijker.jpg'
		,'/images/portfolio/projects/stutz-oldtimer/onstekingsas_0.jpg'
		,'/images/portfolio/projects/stutz-oldtimer/onstekingsas_1.jpg'
		,'/images/portfolio/projects/stutz-oldtimer/onstekingsas_2.jpg'
		,'/images/portfolio/projects/stutz-oldtimer/onstekingsas_3.jpg'
		// ,['/images/portfolio/projects/oudste-tram/tram_12.jpg', '/images/portfolio/projects/oudste-tram/tram_11.jpg']

	]
}));

_company.AddProject(new Project({
	name: "Oudste tram van Nederland"
	,description: <>
		<P>Dit was een heel mooi project om een bijdrage te leveren aan ons rijdend Nederlands erfgoed.</P>
		<P>Schreuder Metaal en Techniek was gevraagd om mee te werken aan het weer rijdbaar krijgen van de oudste stoomtram van Nederland.</P>
		<P>De tekeningen werden deze keer aangeleverd maar de fabricage van de onderdelen van de gehele Gresham & Graven injector zijn geheel gedraaid en gefreesd door ons.</P>
		<P>Dit zijn uiteindelijk weer de pareltjes van het machinebankwerken waar ons hart sneller van gaat kloppen.</P>
	</>
	,image: '/images/portfolio/projects/oudste-tram/tram_11.jpg'
	,imageArray: [
		['/images/portfolio/projects/oudste-tram/tram_12.jpg', '/images/portfolio/projects/oudste-tram/tram_11.jpg']
		,'/images/portfolio/projects/oudste-tram/tram_2.jpg'
		,'/images/portfolio/projects/oudste-tram/tram_3.jpg'
		,'/images/portfolio/projects/oudste-tram/tram_1.jpg'
		,'/images/portfolio/projects/oudste-tram/tram_4.jpg'
		,'/images/portfolio/projects/oudste-tram/tram_5.jpg'
		,'/images/portfolio/projects/oudste-tram/tram_6.jpg'
		,'/images/portfolio/projects/oudste-tram/tram_7.jpg'
		,'/images/portfolio/projects/oudste-tram/tram_8.jpg'
		,'/images/portfolio/projects/oudste-tram/tram_9.jpg'
		,'/images/portfolio/projects/oudste-tram/tram_10.jpg'
	]
}));

_company.AddProject(new Project({
	name: "Extrudervijzel volledig RVS"
	,vertical: true
	,description: <>
	<P>
	Voor een klant in de voedingsindustrie hebben wij een set extrudervijzels gemaakt om zeer fijne puree te maken.
	</P>
	<P>
	Dit betreft een wormvijzel en een huis met zeefgaten die volledig uit rvs 316 zijn gemaakt en volgens een plasma hardingsproces zijn gehard.
	</P>
	<P>
	Dit geheel is vervolgens nageslepen en op elkaar pasgemaakt.
	</P>
	</>
	,imageArray: [
		'/images/portfolio/projects/extruder-vijzel/extruder-vijzel_0.jpg'
		,'/images/portfolio/projects/extruder-vijzel/extruder-vijzel_2.jpg'
		,'/images/portfolio/projects/extruder-vijzel/extruder-vijzel_3.jpg'
		,'/images/portfolio/projects/extruder-vijzel/extruder-vijzel_1.jpg'
	]
}));

_company.AddProject(new Project({
	name: "Bronzen Giektop"
	,label: "Eindresultaat Bronzen Giektop"
	,vertical: true
	,description: <>
		<P>
		Voor een klant in luxe jachtbouw hebben wij diverse opdrachten om zoveel als mogelijk exacte replica`s te maken van antieke beslagdelen.
		<br/>
		Hier afgebeeld een deel van een bronzen giektop waarvan er maar één deel aanwezig was.
		<br/>
		Er moest een tweede deel volgens de eisen van de klant gemaakt worden.
		<br/>
		</P>
		<P>
		Normaal gesproken moet alles glad en strak zijn, hier werd het tegendeeel gevraagd:
		<i>"Kunnen jullie het zo maken dat het eruitziet als een onderdeel van honderd jaar oud?"</i>
		<br/>
		Ik denk dat we er goed in geslaagd zijn om dit te realiseren.
		</P>
	</>
	,imageArray: [
		'/images/portfolio/projects/bronzen-giektop/bronzen-giektop_4.jpg'
		,'/images/portfolio/projects/bronzen-giektop/bronzen-giektop_0.jpg'
		,'/images/portfolio/projects/bronzen-giektop/bronzen-giektop_eind.jpg'
		,'/images/portfolio/projects/bronzen-giektop/bronzen-giektop_1.jpg'
		,'/images/portfolio/projects/bronzen-giektop/bronzen-giektop_2.jpg'
		,'/images/portfolio/projects/bronzen-giektop/bronzen-giektop_3.jpg'
		,'/images/portfolio/projects/bronzen-giektop/bronzen-giektop_5.jpg'
	]
}));


// --- Employees ---
// let a = entities.employees.peterSchreuder.logo(1);
// console.log(a);

// !!! This is only meant for the employees complete name and picture. No further personal info.  
// !!! Contact info is direct to that person inside the company (e.g. Phone number / company email)

const _tel = '(+31) 228 52 66 21';

entities.employees.exampleEmployee1 = new Entity({
	name: 'Example Employee'
	,role: "Planner"
	,logo: ImageSwitch('/images/portfolio/custom-laswerk/custom-laswerk-as-01.jpeg')
	,emails: new InfoList('email.example@example.com')
	,phoneNumbers: new InfoList(_tel, _tel + "1")
});

entities.employees.peterSchreuder = new Entity({
	name: 'Peter Schreuder'
	,role: "Programmeur"
	,logo: ImageSwitch('/images/portfolio/custom-laswerk/custom-laswerk-as-01.jpeg')
	,emails: new InfoList('peter.example@example.com')
	,phoneNumbers: new InfoList('(+31) 228 52 66 21')
});

entities.employees.dirkDeRoos = new Entity({
	name: 'Dirk De Roos'
	,role: "Bedrijfs Coördinator"
	,logo: '/images/company/people/Dirk 2.JPG'
	,emails: new InfoList('dirk@schreudermetaalentechniek.nl')
	,phoneNumbers: new InfoList('(+31) 228 52 66 21')
});

entities.employees.dennisSchreuder = new Entity({
	name: 'Dennis Schreuder'
	,role: "Directeur eigenaar"
	,logo: '/images/company/people/Dennis 5.JPG'
	,emails: new InfoList('dennis@schreudermetaalentechniek.nl')
	,phoneNumbers: new InfoList('(+31) 228 52 66 21')
});

// Add employees to the contact page list
entities.employees.contactPage = [];
entities.employees.contactPage.push(entities.employees.dennisSchreuder);
entities.employees.contactPage.push(entities.employees.dirkDeRoos);

// --- Functions & Data ---

// export function InfoList(...dataObjects) {
// 	let data = dataObjects;
	
// 	return data;
// };

export function ImageSwitch(...dataArray) {
	return function(_value) {
		return _value ? dataArray[_value] : dataArray[0];
	};
}


