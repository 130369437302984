import React, { useState, useEffect } from "react";
import CardItemVertical from "./CardItemVertical";
import "./CardsVertical.css";
import MachineCards from "../pages/machinepark/MachineCards";

var textTitle = "Machinepark"; //Portfolio

var before = "";
var after = "";

function TitleText(string) {
  return before + string + after;
}

export function CardsVerticalContainer(props) {
  return <>
  <div className="cards_vertical">
        <div>
          {props.title}
        </div>
        <div className="cards_vertical_container">
          <div className="cards_vertical_wrapper">
            <ul className="cards_vertical_items">
              {props.cards}
            </ul>
          </div>
        </div>
      </div>
  </>
}

export default function CardsVertical() {
  const [selectedCategorie, setSelectedCategorie] = useState({
      click: false
    ,path: ""
  }); // Wich categorie is selected
  const [button, setButton] = useState(true); // Button variable

  // Set the variable to the opposite of what it is currently
  const handleClick = (e) => {
    setSelectedCategorie({click: e.click, path: e.path});
    console.log(selectedCategorie);
  };
  //
  const closeMobileMenu = () => setSelectedCategorie(false);
  
  const cards = []
  cards.push(<CardItemVertical
              title={TitleText("Draaibanken")}
              underTitle={undefined}//`Maximale opspanning voor onze draaibanken is: ⌀100mm x 400mm`
              src={`/images/machines/lathes/doosan-lynx.png`}
              // label="Product"
              text={window.basicText}
              date="asd"
              path="lathes"
              active={selectedCategorie.click}
              handleClick={handleClick}
            />);
  cards.push(<CardItemVertical
              title={TitleText("Freesbanken")}
              // underTitle={`Iets over de freesbanken`}
              src={`/images/machines/mills/finetech-gtx620.jpg`}
              // label="Eigen Projecten"
              text={window.basicText}
              date="Product"
              path="milling machines"
              active={selectedCategorie.click}
              handleClick={handleClick}
            />);
  cards.push(<CardItemVertical
              title={TitleText("Meetkamer")}
              underTitle={undefined}//Onze meetkamer en al het meetgereedschap dat wij gebruiken wordt te allen tijde op 21° celcius gehouden
              src={`/images/machines/meetkamer/meetkamer-02.jpg`}
              // label="Eigen Projecten"
              text={window.basicText}
              date="Product"
              path="measure room"
              active={selectedCategorie.click}
              handleClick={handleClick}
              zoom={150}
              // style={{transform: 'rotate(-35deg) scale(1.2) translateX(10%)'}}
            />);
  cards.push(<CardItemVertical
              title={TitleText("Productie")}
              underTitle={undefined}//Onze meetkamer en al het meetgereedschap dat wij gebruiken wordt te allen tijde op 21° celcius gehouden
              src={`/images/machines/misc/zaagmachine_h.jpg`}
              // label="Eigen Projecten"
              text={window.basicText}
              date="Product"
              path="production machines"
              active={selectedCategorie.click}
              handleClick={handleClick}
              zoom={150}
              // style={{transform: 'rotate(-35deg) scale(1.2) translateX(10%)'}}
            />);
  
  return (
    <>
      <CardsVerticalContainer cards={cards} title={<h1 className={`${selectedCategorie.click ? "active" : "notActive"} title`}>{textTitle}</h1>} />
      {selectedCategorie.click ? <Machines cards={cards} title={selectedCategorie.path} /> : ""}
    </>
  );
}

export function Machines(props) {
  console.log(props.cards[0].props.underTitle);
  const machineList = {
    "Draaibanken": <MachineCards underTitle={props.cards[0].props.underTitle} title={props.title} />
    ,"Freesbanken": <MachineCards underTitle={props.cards[1].props.underTitle} title={props.title} />
    ,"Meetkamer": <MachineCards underTitle={props.cards[2].props.underTitle} title={props.title} />
    ,"Productie": <MachineCards underTitle={props.cards[3].props.underTitle} title={props.title} />
  }

  return (
    <>
      {machineList[props.title]}
    </>
  );
}
