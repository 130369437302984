import React from 'react';
import HeaderSection from '../layout/pages/home/HeaderSection';
import CompanyStory from '../layout/text/CompanyStory';
import { importAllArray } from 'images';

import { P } from 'styling/Text';
import {entities} from 'entities.js';
import Gallery from 'components/layout/media/Gallery';
import { Container, ContainerContent, ContainerWrapper, ContainerGroup } from 'components/layout/containers/Containers';
import { InformationFollows } from 'components/layout/text/TextComponents';
import CardsHorizontal from 'components/layout/cards/CardsHorizontal';
import CardItemHorizontal from 'components/layout/cards/CardItemHorizontal';
import { ProjectDisclaimer } from './Home';


const story = undefined;
const title = `Projecten`;

export default function Production () {

	const _imageArray = importAllArray(
		require.context('../../../public/images/portfolio/layout/pages/production', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
        ,'images/portfolio/layout/pages/production'
    );

    return (
        <>
            <div className="page">
                {/* <HeaderSection video={'/videos/video-1.mp4'} showInfo={true} logo={true} /> */}
                {/* <HeaderSection video={_media} showInfo={true} logo={true} /> */}
                {/* <HeaderSection image={_image} showInfo={true} logo={true} /> */}
                <HeaderSection title={`${title}`} mediaHeight={'450px'} imageArray={_imageArray} showInfo={false} logo={false} />


                <div className="page-content wide">
                    <CompanyStory title={'Kwaliteit kunnen wij moeilijk verborgen houden'}>
                        <P>
                        Door de jaren heen hebben wij hele mooie projecten mogen uitvoeren, dit heeft geresulteerd in een brede portfolio aan beeldmateriaal.
                        </P>
                        <P>
                        Wij nodigen u graag uit om eens een kijkje te nemen in onze bibliotheek van de meest bijzondere (ervaringen) projecten die wij hebben mogen maken.
                        </P>
                        <hr/>
                        <ProjectDisclaimer />
                    </CompanyStory>

                </div>
                    <CardsHorizontal _key={1123} 
                        title={''}
                        projects={entities.companies.SchreuderMetaalEnTechniek.GetProjects()}
                        // projects={undefined}
                        // cards={cards}
                    />
            </div>
        </>
    );
};

const cards = <>
<CardItemHorizontal
key={'3451'}
    title={`Extrudervijzel volledig RVS`}
    src={`/images/portfolio/placeholder/placeholder-product-01.jpg`}
    // label={`Product`}
    text={<>
        <P>
        Deze roestvrijstalen extruder vijzel is gemaakt in opdracht voor een babyvoeding fabrikant
        </P>
        <P>
        Deze onderdelen voldoen aan de hoge foodgrade eisen, met name steriliteit en geen abrasieve vervuiling in de verwerking van voedsel.
        </P>
        <P>
        Beide delen zijn gemaakt van rvs 316 met de nodige certificaten en oppervlaktebehandelingen m.b.t. tracebility in de gehele keten.
        </P>
        <P>
        Maatvoering is in deze belangrijk dat de vereiste toleranties voldoen voor een goede werking van de vijzel en tegelijkertijd minimale ophoping van voedselresten achterlaten.
        </P>
        <P>
        De slijtvastheid van rvs onderdelen kan tegenwoordig met goede hardingsmethoden ook voor rvs 316 gewaarborgd worden. Dit is gebeurd d.m.v. plasmanitreren.
        </P>
        <P>
        De duurzaamheid in vergelijking met de oude stalen uitvoering is aanzienlijk verbeterd met een factor 5, minder slijtage en een besparing op de schoonmaakkosten.
        </P>
    
    </>}
    date={`30-01-2023`}
    path={`/factuur`}
            // logo={global.config.companyMedia.logo}
  />
  <CardItemHorizontal
  key={'3452352'}
    title={`Bronzen Giektop`}
    src={`/images/portfolio/projects/bronzen-giektop/Eindresultaat Bronzen Giektop.jpg`}
    label={`Eindresultaat Bronzen Giektop`}
    text={<>
    <P>
      Voor een klant in luxe jachtbouw hebben wij diverse opdrachten om zoveel als mogelijk exacte replica`s te maken van antieke beslagdelen.
      <br/>
      Hier afgebeeld een deeel van een bronzen giektop waarvan er maar één deel aanwezig was.
      <br/>
      Er moest een tweede deel volgens de eisen van de klant gemaakt worden.
      <br/>
      </P>
      <P>
      Normaal gesproken moet alles glad en strak zijn, hier werd het tegendeeel gevraagd:
      <i>"Kunnen jullie het zo maken dat het eruitziet als een onderdeel van honderd jaar oud?"</i>
      <br/>
      Ik denk dat we er goed in geslaagd zijn om dit te realiseren.
    </P>
    </>}
    date={`30-01-2023`}
    path={`/portfolio`}
            // logo={`/logo/placeholder_logo2.png`}
            // logoInImage='true'
    // mirror={`true`}
  />
</>;