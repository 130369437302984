import config from 'config';
import 'App.css';
// import '../../../../../App.css'
import { Button } from 'components/layout/interacion/button/Button';
import './CompanyStory.css';
import parse from 'html-react-parser';
import { HRLine, P } from 'styling/Text';


export default function CompanyStory(props) {
    let _textTitle = props.title ? parse(props.title) : undefined;
    let _children = props.children;
    let _story = props.story ? parse(props.story) : (_children ? "" : <P>{window.basicText}</P>);

    let _line = (props.line == false) ? "" : <HRLine />;

    return (
        <div style={{...props.style}} className='company-info'>
            <div className='company-info-content'>
                
                {(_textTitle) ? <h1 className={`${props.centerTitle ? 'text-center' : ''} `}>{_textTitle}</h1>
                : <></>}

                {_line}

                <div className='company-info-story'>
                
                    {/* <p>{companyName} {companyStory}</p> */}
                    {_story}
                    {_children}

                </div>

            </div>

            
        </div>
    )
}
