import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import '../interacion/socialmedia/Socialmedia.css';
import { Button } from '../interacion/button/Button';


import FooterLinks from './components/FooterLinks';
import FooterCTA from './components/FooterCTA';
import FooterSocialMedia from './components/FooterSocialMedia';

function Footer() {
  const _author = <>
    Front- & backend door Peter Schreuder
  </>;

  const _mkg = <>
  
  <small className='glow-image-black' style={{margin: 'auto', display: "flex", alignItems: "center"}}>
    <span className='text-white'>
      Onze bedrijfsprocessen zijn geoptimaliseerd met
    </span>
      
      <a target='blank' href='http://MKG.eu'><img style={{marginLeft: "8px", width: "28px"}} src={window.publicURL + '/images/misc/MKG.png'}></img></a>
      <a target='blank' href='http://MKG.eu' style={{marginLeft: "8px", width: "32px"}}>MKG.eu</a>
  </small>
  
  </>;
    return (
      <footer className='footer-container bgr'>

        {/* <FooterCTA/> */}

        {/* <FooterLinks/> */}

        {/* Social media links */}
        <section className="social-media glow-image-black">
          <div className="social-media-wrap">
            <a href="/" title='Home'><img className="logo footer-logo" src={window.publicURL + global.config.companyMedia.logoWhite} width="200px"></img></a>
                {/* <div className="footer-logo">
                    <Link to='/' className="social-logo">
                        TRVL <i className="fab fa-typeo3"></i>
                    </Link>
                </div> */}
            <small className="website-rights text-center">{global.config.companyInfo.name} © {global.config.websiteInfo.creationDate} <br /> {_author}</small>
                <br/>
                
            <FooterSocialMedia/>
            
            <img style={{width: "90px", float: 'right'}} src={window.publicURL + '/images/misc/erkend-leerbedrijf.png'}></img>

            {/* erkend-leerbedrijf.png */}

          </div>

        
          
        </section>
                
          
          {_mkg}
          
            
      </footer>
    );
  }

export default Footer
