import { Container, ContainerContent, ContainerGroup, ContainerWrapper } from 'components/layout/containers/Containers';
import { useState } from 'react';

// Funtions 

/*
<List 
title={"Title of the list"} 
items={[
    "asdfasdf"
    ,`asdfafa
    asdfasdfaf`
]}
/>
*/
export function List(props) {
    let _return = <></>;
    let title = props.title;
    let subTitle = props.subTitle;
    let items = props.items;
    let bulletPoints = props.bulletPoints == undefined || props.bulletPoints != false ? true : false;
    let _bulletPoints = bulletPoints ? {} : {listStyleType: 'none',
        margin: '0',
        padding: '0'};
    let numbers = props.numbers ? {listStyleType: 'number'} : {};

    let _tempitems = items.slice();

    let i = 0;
    _tempitems.forEach(item => {
        _tempitems[i] = <>
        <li style={{...props.itemStyle, fontSize: 'large'}} className={`font-p ${props.itemClass}`}>
            {_tempitems[i].children ? _tempitems[i].children : _tempitems[i]}
        </li>
        </>;

        i++;
    });

    items = _tempitems;

    return <div style={{...props.style}} className={`${props.className ? props.className : ''}`}>
        {title ? <h3 className=''>{title}</h3> : ''}
        {subTitle ? <h3 className='bold'>{subTitle}</h3> : ''}
        <ul className='font-p padding-left' style={{..._bulletPoints, ...numbers, lineHeight: `${props.lineHeight ? props.lineHeight : '1.4'}`}}>
            {items}
        </ul>
    </div>
}

export function Dimensions(props) {
    const _x = props.x;
    const _y = props.y;
    const _z = props.z;

    return <>
        XYZ {_x}x{_y}x{_z} <sup>(in mm)</sup>
    </>;
}

export function PhotoAndText(props) {
    const [left, setLeft] = useState(props.imageLeft || (window.isPhoneSize));
    const _text = props.children;
    let _imageWide = (props.imageWide == undefined || props.imageWide != false) ? true : false;
    let _imageRound = (props.round == undefined || props.round != true) ? 'round-edge-big' : 'round';

    const _imageWideString = props.width ? '' : (_imageWide ? 'width100 pwidth100' : 'width50 pwidth100');

    const _background = props.background == undefined || props.background != true ? {} : {backgroundColor: 'white'};

    const _image = <>
        {props.beforeImage}
        <div className='text-center overflow-hidden' style={{top: '-10%', display: 'inline-block'}}>
            <img style={{width: `${props.width}%`, ..._background, paddingTop: "-100%"}} className={`${_imageWideString} ${_imageRound} glow-image`} src={props.image}/>
        </div>
            <br/>
            {/* <hr/> */}
            {props.afterImage}
    </>;
// ${props.width}
// ${_imageWide ? 'width100 pwidth100' : 'width50 pwidth100'}
    function checkSize() {
        if (window.isPhoneSize) {
            setLeft(true);
        } else if (left) {
            setLeft(props.imageLeft || false);
        }
    };

    window.addEventListener("resize", checkSize);

    const _margin = left ? { margin: '13% 2%'} : {margin: '2% 20% 2% 12%'};


    return <>
    <ContainerGroup className="photo-text-group" style={{justifyContent: 'space-evenly', alignItems: 'stretch'}} gap={{ columnGap: "0%" }}>
        <ContainerWrapper className={'photo-text-image container-wrapper-grow width50 pwidth100'} style={{ display: 'flex' }}>
            <Container style={{overflow: 'visible', alignItems: 'stretch'}} background={false}>
                <ContainerContent noPadding={true}>
                {(left) ? _image : _text}
                </ContainerContent>
            </Container>
            </ContainerWrapper>
            
        <ContainerWrapper className={'container-wrapper-grow width30 pwidth100'} style={{ display: 'flex' }}>
            <Container noPadding={true} style={{overflow: 'visible', ..._margin}} background={false} >
                <ContainerContent noPadding={true}>
                {(left) ? _text : _image}
                </ContainerContent>
            </Container>
        </ContainerWrapper>
    </ContainerGroup>
    </>;
}

export function SpaceBig() {
    return <div style={{margin: "5% 0%"}}></div>
}

export function InformationFollows() {
    return <>
        <h1 className='text-center h1'>Verdere informatie volgt..</h1>
        <SpaceBig />
    </>;
}