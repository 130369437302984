export function CheckCollapsibleText() {
    var coll = document.getElementsByClassName("text-collapsible");
    var i;
    
    for (i = 0; i < coll.length; i++) {
    coll[i].addEventListener("click", function() {
        this.classList.toggle("active");
        console.log('click');
        var content = this.nextElementSibling;
        if (content.style.maxHeight){
            content.style.maxHeight = null;
        } else {
            content.style.maxHeight = content.scrollHeight + "px";
        } 
    });
    }

    return <></>;
}

export function HRLine(props) {
    return <><hr className={`textUnderLine ${props.className}`}></hr></>
}

export function P(props) {
    return <>
    <p className={`${props.center ? 'text-center' : ''} ${props.small ? 'font-pm' : 'font-p'} ${props.className} ${props.italic ? 'italic' : ''}`} style={{...props.style}}>
    {props.children}
    </p>
    {props.noBreak == true ? '' : <br/>}
    </>;
}

// P medium size
export function PM(props) {
    return <>
    <p className={`font-pm ${props.className}`} style={{...props.style}}>
    {props.children}
    </p>
    {props.noBreak == true ? '' : <br/>}
    </>;
}

export function Title(props) {
    return <>
    <h3 className=''>
    {props.children}
    </h3>
    </>;
}

export function TitleText(props) {
    return <>
    <br/>
        <h1 className={`${props.center ? 'text-center' : ''} text-accent-`}>{props.children}</h1>
    <br/>
    </>
}

export function TitleTextBig(props) {
    return <>
    <h1 className={`${props.center ? 'text-center' : ''} text-black2`} style={{ fontSize: "50px" }}>{props.children}</h1>
    </>
}