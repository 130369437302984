import React from "react";

export class ContainerGroup extends React.Component {
  
  render() {
    let _gap = this.props.gap != undefined ? {...this.props.gap} : {columnGap: "10%"};
  const className = this.props.className ? this.props.className : '';
      return <div className={`container-group ${className} ${(this.props.background == true) ? 'container-background' : ''}`} style={{...this.props.style, ..._gap}}>{this.props.children}</div>
  }
}

export class ContainerWrapper extends React.Component {
  render() {
    let _width = this.props.width != undefined ? { width: this.props.width } : {width: "100%"};
  const className = this.props.className ? this.props.className : '';
      return <div className={`container-wrapper ${className}`} style={{...this.props.style, ..._width}}>{this.props.children}</div>
  }
}
  
//   Set 'background' to false to remove the background
export class Container extends React.Component {
  
  render() {
    const _borderStyle = this.props.borderLeft != undefined ? { borderColor: "transparent", borderLeft: "rgb(195, 25, 61)", borderStyle: "inset solid", borderWidth: "0px 0px 0px 10px" } : {};
        
		const className = this.props.className ? this.props.className : '';
      	return <div className={`m-container ${(this.props.background == undefined || this.props.background == true) ? 'container-background' : ''} ${className}`} style={{...this.props.style, ...this.props.backgroundStyle, ..._borderStyle}}>{this.props.children}</div>
    }
}
  
export class ContainerContent extends React.Component {
  constructor(props) {
    super(props);


  }  

  render() {
    const props = this.props;
    const _noPadding = (props.noPadding != undefined && props.noPadding != false) ? {padding: '0'} : {};

  const className = this.props.className ? this.props.className : '';
      return <div className={`container-content ${className}`} style={{...this.props.style, ..._noPadding}}>{this.props.children}</div>
  }
}


// import { Container, ContainerContent, ContainerGroup, ContainerWrapper } from 'components/layout/containers/Containers';


{/* <ContainerGroup>
  <ContainerWrapper>
      <h1 className="container-title">Neem contact met ons op</h1>
      <Container background={false}>
          <ContainerContent>
          
          
          
          </ContainerContent>
      </Container>
  </ContainerWrapper>
  <ContainerWrapper >
      <Container background={false}>
          <ContainerContent>


            
          </ContainerContent>
      </Container>
  </ContainerWrapper>
</ContainerGroup> */}