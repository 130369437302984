import React from 'react';
import HeaderSection from '../layout/pages/home/HeaderSection';
import CompanyStory from 'components/layout/text/CompanyStory';
import Cards from '../layout/cards/Cards';
import CardsHorizontal from '../layout/cards/CardsHorizontal';
import { Container, ContainerContent, ContainerGroup, ContainerWrapper } from 'components/layout/containers/Containers';
import { Slideshow, importAll, importAllArray } from 'images';
import parse from 'html-react-parser';

import {entities} from 'entities.js';
import Gallery from 'components/layout/media/Gallery';
import { P, Title, TitleText } from 'styling/Text';
import { InformationFollows, PhotoAndText, SpaceBig } from 'components/layout/text/TextComponents';
import { ButtonSmall } from './Vacatures';
import { Fade } from '../../../node_modules/react-slideshow-image/dist/index';
import { Shuffle } from 'components/tools/arrays';
import PartnerCards from 'components/layout/PartnerCards';
import { Link } from '../../../node_modules/react-router-dom/index';
// import { Link } from '../../../node_modules/react-router-dom/cjs/react-router-dom';

var textTitle = 'Voor bedrijven & particulieren';

var companyName = <b>{global.config.companyInfo.name}</b>;

var companyStory = <>
<P>
{companyName} is een dynamisch bedrijf dat zich richt op het verspanen van diverse materialen.
U kunt bij ons terecht voor enkelstuks- en/of serieproducties en het repareren van diverse machine onderdelen.
Wij beschikken over een goed uitgeruste werkplaats en een ruime praktische kennis en ervaring.
Samen met u bedenken we een oplossing voor het realiseren van uw gewenste product.
</P>
    
<P>
Wij bieden de unieke mix van prototyping tot serieproductie en
daarbij kunnen wij een compleet pakket leveren die aansluit op alle wensen van onze klanten.
</P>

<P>
Mede door onze uitgebreide ervaring met prototyping (enkelstuks) kunnen wij veel ondersteuning bieden
in de ontwikkelingsfase van het project en een adviserende rol spelen op het gebied van <_link>tekenwerk en enginering</_link>.
</P>

<P>
Wij kunnen ook meedenken en adviseren over de maakbaarheid en materiaalkeuze voor de bewuste toepassing,
zodat er een efficiënt en kostenbesparend productieproces kan worden gevormd naast een goed werkend concept.
</P>

<P>
Door onze zeer diverse klantenkring hebben wij een uitgebreide portfolio aan <_link>materialen</_link>, 
gereedschappen en een stabiel netwerk opgebouwd voor diverse <_link>nabehandelingen</_link>.  
</P>

</>;//

if (!companyStory) {
    companyStory = window.basicText;
}

function Scroll() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
  })
}

function _link(props) {
    return <>
        {/* <a href='asdf'>{props.children}</a> */}
        {props.url ? <Link to={props.url}>{props.children}</Link> : <a href={props.url}>{props.children}</a>}
        {/* {props.url ? <Link to={props.url} onClick={Scroll()}>{props.children}</Link> : <a href={props.url}>{props.children}</a>} */}
        {/* <a href={props.url}>{props.children}</a> */}
        
        {/* {props.children} */}
    </>
}

export default function Home () {
	let _video = `${process.env.PUBLIC_URL}/videos/video-1.mp4`;
	let _image = `${process.env.PUBLIC_URL}/images/portfolio/layout/pages/home/20210526_121644.jpg`;
	// let _imageArray = `${process.env.PUBLIC_URL}/images/portfolio/layout/pages/home/20210526_121644.jpg`;

	const _imageArray = importAllArray(
		require.context('../../../public/images/portfolio/layout/pages/home', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
		// require.context('../../../public', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
        // require.context('%process.env.PUBLIC_URL%/images', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/, 'lazy')
        , "images/portfolio/layout/pages/home"
    );

    // function importAllImages(r) {
    //     return r.keys().map(r);
    // }
      
    // const images = importAllImages(require.context('../../../public', false, /\.(png|jpe?g|svg)$/));
    
    // console.log(images);
    
	const _imageArray2 = importAllArray(
		require.context('../../../public/images/portfolio/layout/pages/production', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
        , "images/portfolio/layout/pages/production"
    );

    let _imageBgr = `${window.publicURL}/images/machines/meetkamer/tesa-micro-hite-3d.png`;
    
    const _measuring_button = <>
        <div>
            <h1 style={{ marginBottom: '1%' }}><ButtonSmall title={"Neem contact op"} /></h1>
        </div>
    </>;

    const _pictureGallery = (<>{<ContainerGroup gap={{ columnGap: '0%' }}>
        <ContainerWrapper className={"width50 pwidth100"}>
            <Container background={false}>
                <ContainerContent>
            
                    <_gallery />
            
                </ContainerContent>
            </Container>
        </ContainerWrapper>
        <ContainerWrapper className={"width50 pwidth100"}>
            <Container background={false}>
                <ContainerContent>

                    <_gallery array={_imageArray2} />
                
                </ContainerContent>
            </Container>
        </ContainerWrapper>
    </ContainerGroup>}</>);

    function _gallery(props) {
        const _array = props.array ? props.array : _imageArray;

        // Shuffle(_array);
        return <>
            <div className='container-glow'>
                <div className={props.rounded == false ? "" : "rounded-border"} style={{ marginBottom: props.noMargin ? '' : '2%' }}>
                    <Fade duration={4000} arrows={false} infinite={true}>
                        {_array.map((_image, _index) => (
                            <div className='gallery-image' key={_index} style={{ width: '100%', height: props.height ? props.height : '300px'}}>
                                <img className='header-slideshow' style={{ width: '100%' }} src={_image} />
                                {/* <h2>{fadeImage.caption}</h2> */}
                            </div>
                        ))}
                    </Fade>
                </div>
            </div>
        </>
    }

    function _gallery(props) {
        const _array = props.array ? props.array : _imageArray;

        // Shuffle(_array);
        return <>
            <div className='container-glow'>
                <div className={props.rounded == false ? "" : "rounded-border"} style={{ marginBottom: props.noMargin ? '' : '2%' }}>
                    <Fade duration={4000} arrows={false} infinite={true}>
                        {_array.map((_image, _index) => (
                            <div className='gallery-image' key={_index} style={{ width: '100%', height: props.height ? props.height : '300px'}}>
                                <img className='header-slideshow' style={{ width: '100%' }} src={_image} />
                                {/* <h2>{fadeImage.caption}</h2> */}
                            </div>
                        ))}
                    </Fade>
                </div>
            </div>
        </>
    }

    
    
    // , bottom: '50%', top: '50%'
    const _measuring_message = <>
        <div style={{ borderRadius: '10px', overflow: 'hidden'}}>
            <div style={{ position: 'relative'}}>
                <div className='backgroundTest' style={{}}>
                    <img className='backgroundTestImage' style={{ height: "100%" }} src={`${window.publicURL}/images/machines/meetkamer/meetkamer-02 (Medium).jpg`}></img>
                </div></div>
            <PhotoAndText image={`${window.publicURL}/images/machines/meetkamer/tesa-micro-hite-3d.png`} width={'100'} imageLeft={false}>

                <div style={{ position: 'relative', height: '100%', color: 'white' }} className='photo-text-container'>
                    <div className='text-middle text-measuring'>
                        <h2 className='photo-text-title'>Meetrapport op aanvraag</h2>
                        <br/>
                        <P className="photo-text">
                            De producten die wij voor u maken worden 
                            in onze geconditioneerde ruimte gemeten. En op verzoek met meetrapport geleverd.
                        </P>

                        {/* {_measuring_button} */}
                        
                        
                    </div>
                </div>

            </PhotoAndText>
        </div>
        <SpaceBig />
    </>;

    function AdviceItem(props) {
        const readMore = false;
        const imageURLRight = 'images/portfolio/layout/pages/contact/meetkamer-01E (Medium).jpg';

        // const _style = {background: `linear-gradient(120deg, rgba(255,255,255,1) 0%, rgba(255,255,255,.5) 45%, rgba(255,255,255,0) 48%, rgba(255,255,255,0) 100%)`}
        const _style = {};
        
        // const _offset = props.offset ? {marginLeft: props.offset} : {};
        const _offset = props.offset ? {objectPosition: `${props.offset}`} : {};
        // const _offset = props.offset ? {transform: `translate(${props.offset})`} : {};

        return <>
        <ContainerWrapper className={``}>
            {/* <h3>{props.title}</h3> */}
            <Container>
                {/* style={{height: '300px', position: 'relative', width: '50%', zIndex: '100'}} */}
                <ContainerContent style={{..._style}} className={props.imageURLRight ? `container-image-right-content` : ''} >
                
                    <div className={`container-image-right-content-clip`}></div>
                    <div>
                    <h3 className={props.titleClass}>{props.title}</h3>
                    <HrLight/>
                    <div className='brSmall'/>
                    <P small>
                        {props.children}
                    </P>
                    {readMore ? <><a href={props.url ? props.url : 'asdf'}><h4>Lees Meer {'>'}</h4></a>
                    <br/></> : ''}
                    </div>


                </ContainerContent>

                    {props.imageURLRight ?
                    <div className='container-image-right'>
                        <img style={{..._offset, backgroundColor: 'black'}} src={`${window.publicURL}/${props.imageURLRight}`}></img>
                        <div className='container-image-right-cover' />
                    </div>
                    : ''}


                    {props.imageURL ? <ContainerContent className={'container-image'}>
                        <img src={`${window.publicURL}/${props.imageURL}`}></img>
                </ContainerContent> : ''}
            </Container>
            
        </ContainerWrapper>
        <div>
            
        </div>
        </>
    }
    // function AdviceItem(props) {
    //     return <>
    //     <div>
    //         <hr/>
    //         <h3>{props.title}</h3>
    //         <br/>
    //         <P small>
    //             {props.children}
    //         </P>
    //         <a href={props.url ? props.url : 'asdf'}><h4>Lees Meer {'>'}</h4></a>
    //         <br/>
    //     </div>
    //     </>
    // }

    const _testImages = <>
    <div className='p-4'>
        <div className='container-background'>
        <div className='rounded overflow-hidden'>
            <div className='grid grid-cols-2 gap-2' style={{height: `300px`}}>
                <_Item c={``} url={'images/portfolio/projects/extruder-vijzel/extruder-vijzel_0.jpg'} />
                <_Item c={``} url={'images/portfolio/projects/bronzen-giektop/bronzen-giektop_4.jpg'}/>
            </div>
            {/* <div className='absolute top-1/2 width100'>
                <h1 className='text-center text-red'>asdfasdf</h1>
                <h1 className='text-center  text-white'>asdfasdf</h1>
                <h1 className='text-center  text-white'>asdfasdf</h1>
            </div> */}
        </div>
        <div className='home-projects-info'>
            {/* <div> */}
            {/* <br/> */}
            <P center noBreak className={'py-2'}>
                We nodigen u graag uit om eens een kijkje te nemen in onze <_link url={`${window.publicURL}/about-projects`}>Galerij</_link>.
            </P>
            {/* </div> */}
        </div>
        </div>
    </div>
    </>

    return (
        <>
            <div className="page">
                {/* <HeaderSection video={'/videos/video-1.mp4'} showInfo={true} logo={true} /> */}
                {/* <HeaderSection video={_media} showInfo={true} logo={true} /> */}
                {/* <HeaderSection image={_image} showInfo={true} logo={true} /> */}
                <HeaderSection imageArray={_imageArray} showInfo={true} logo={true} />

                <div className='home-banner'>
                    <h2 className='text-center width100 max-md:text-sm lg:text-2xl'>Welkom bij Schreuder metaal en techniek.  (De duurzame oplossing voor uw project)</h2>
                </div>

                <div className="page-content">


                <div className='width100 flex flex-auto flex-wrap'>
                    <div className='width50 pwidth100'>
                        <div>
                            <CompanyStory title={textTitle}>
                                {companyStory}
                            </CompanyStory>
                        </div>
                        
                        <div>
                            <CompanyStory line={false}>
                                <AdviceItem title={<>Kwaliteitscontrole en borging:</>} link={`asdf`} imageURL={'images/portfolio/layout/pages/contact/meetkamer-01E (Medium).jpg'}>
                                    Wij willen onze klanten graag bedienen van een hoogwaardig kwaliteitsproduct en de daarbij bijbehorende <_link>rapportages</_link> en <_link>materiaalcertificering</_link>.
                                    <br/>
                                    <br/>
                                    Daarbij maken wij gebruik van onze <_link>geklimatiseerde</_link> <_link>meetkamer</_link> met diverse gespecialiseerde meetinstrumenten.
                                </AdviceItem>
                                
                                {/* <AdviceItem titleClass='text-center' title={<>Bekijk onze tijdlijn:</>} link={`asdf`}>
                                    <_link url={window.publicURL + '/nieuws-items'}><h1 className='text-center'>2007 {'>'} {new Date().getFullYear()}</h1></_link>
                                </AdviceItem> */}
                                
                            </CompanyStory>
                        </div>

                    </div>

                    <div className='width50 pwidth100'>
                        <div>
                            <CompanyStory centerTitle title={'Onze Projecten'} line={false}>
                                <br/>
                                <P center noBreak>
                                    Een kijkje in onze portfolio van de projecten die wij hebben mogen doen de afgelopen jaren:
                                </P>

                                {_testImages}

                                {/* <P center>
                                    We nodigen u graag uit om eens een kijkje te nemen in onze <_link>Galerij</_link>.
                                </P> */}

                                <ProjectDisclaimer />

                            <hr/>
                            </CompanyStory>
                        </div>

                            
                        <div>
                            <CompanyStory line={false}>

                                <AdviceItem title={<>Ondersteuning en Advies:</>} link={`asdf`} imageURLRight={`images/portfolio/layout/components/advice/ondersteuning.JPG`}>
                                    Wij kunnen naast onze verspanende bewerkingen op meerder vlakken een 
                                    adviserende of ondersteunende rol betekenen in zowel tekenwerk en enginering.
                                </AdviceItem>
                                <AdviceItem title={<>Prototyping:</>} link={`asdf`} imageURLRight={`images/portfolio/layout/pages/assembly/IMG-20201002-WA0003 (Medium) - Copy.jpg`}>
                                    Wij hebben ons de afgelopen 15 jaar onderscheiden in het ondersteunen van de ontwikkeling en uitvoeren van onderdelen voor de medische sector en machinebouw.
                                </AdviceItem>
                                <AdviceItem offset={'12%'} title={<>Serieproductie:</>} link={`asdf`} imageURLRight={`images/portfolio/layout/pages/assembly/20210526_121637 (Medium).jpg`}>
                                {/* <AdviceItem offset={'-30px'} title={<>Serieproductie:</>} link={`asdf`} imageURLRight={`images/portfolio/layout/pages/home/20210526_121644 -2.jpg`}> */}
                                    {/* <P style={{fontSize: '50%'}}>Onze <_link>machinepark</_link> is uitermate geschikt om kleine tot middelgrote series ( 5 tot 5000 stuks) te verwerken.<br/>Hiermee bieden wij niet alleen extra mogelijkheden na de ontwikkelingsfase, maar kunnen wij ook een stabiel platform verzorgen voor zowel de kleine als de grote onderneming</P> */}
                                    Onze <_link>machinepark</_link> is uitermate geschikt om kleine tot middelgrote series (5 tot 5000 stuks) te verwerken. Hiermee bieden wij niet alleen extra mogelijkheden na de ontwikkelingsfase, maar kunnen wij ook een stabiel platform verzorgen voor zowel de kleine als de grote onderneming
                                </AdviceItem>

                            </CompanyStory>
                        </div>
                    </div>
                </div>


                
                    

                    

                    {/* <SpaceBig /> */}
                    
                    
                     {/* {_measuring_message} */}

                    
                    {/* <Gallery path={process.env.PUBLIC_URL}/> */}
                    
                    {/* <CardsHorizontal _key={1123} 
                        // projects={entities.companies.SchreuderMetaalEnTechniek.GetProjects()} 
                        projects={undefined} 
                        cards={undefined}
                    /> */}

                    {/* Partners */}
                    <div className='width100' style={{width: '1440px'}}>
                        <PartnerCards />
                    </div>

                    

                    {_pictureGallery}


                    {/* {InformationFollows()} */}

                </div>
            </div>
        </>
    );
};

export function ProjectDisclaimer() {
    return <>
    <P small italic noBreak center className={'px-4'}>
                                    *De projecten die wij etaleren zijn reeds uitontwikkeld of met toestemming van de opdrachtgever vrijgegeven om te laten zien op ons medium.
                                </P></>;
}

function HrLight(props) {
    return <hr className='hr-light'/>
}

function _Item(props) {
    return <>
        <div className={`${props.c}`} style={{ width: '100%' }}>
            
            {/* <img className='home-image' style={{ width: '100%', height: '100%' }} src={`${process.env.PUBLIC_URL}/images/portfolio/projects/bronzen-giektop/Eindresultaat Bronzen Giektop.jpg`} /> */}
            <img className='home-image' style={{ width: '100%', height: '100%' }} src={`${process.env.PUBLIC_URL}/${props.url}`} />
            {/* <img className='home-image' style={{ width: '100%', height: '100%' }} src={`${process.env.PUBLIC_URL}/images/placeholder/no-image-placeholder.jpg`} /> */}
        </div>
    </>
}

const _asdf = <>
<div className='p-4'>
    <div className='rounded overflow-hidden'>
            <div className='grid grid-cols-3 gap-2' style={{height: `500px`}}>
                <_Item c={`row-span-2`} />
                <_Item c={``} />
                <_Item c={``} />
                <_Item c={`col-span-2`} />
            </div>
            <div className='absolute top-1/2 width100'>
                <h1 className='text-center text-red'>asdfasdf</h1>
                <h1 className='text-center  text-white'>asdfasdf</h1>
                <h1 className='text-center  text-white'>asdfasdf</h1>
            </div>
        </div>
</div>
</>