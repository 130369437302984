import React from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { Container, ContainerContent, ContainerGroup, ContainerWrapper } from 'components/layout/containers/Containers';
import { HRLine } from 'styling/Text';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

/*
this.owner = props.owner;
this.name = props.name || "Example Project";
this.description = props.description || (basicText + basicText + basicText);
this.label = props.label;
this.date = props.date;
this.image = props.image;
*/

// to={props.path}
export default function CardItemHorizontal(props) {

  var s = props.style;

  var _type = props.type ? props.type : '';
	
var _phone = window.isPhoneSize;

var _mirror = _phone ? true : props.mirror;

var _thumbnailsBottom = props.thumbnailsBottom;

var _label = props.title;//_phone ? props.title : props.label;

  var _logo_url = props.logo ? window.publicURL + props.logo : undefined;

var _logoInImage = props.logoInImage;// Boolean

const _multipleImage = props.imageArray != undefined && props.imageArray.length > 1;

let images = [];

// var _label = _phone ? props.title : props.label;


function ImageLoop(_array) {
	let _return = <></>;
	let _items = <></>;

	_array.forEach(element => {
		ImageItem(element);
	});
}

function ImageItem(_item) {
	let _return = <></>;

	if (Array.isArray(_item)) {
		if (!_phone) {
			const _a = () => {return GroupImageItem(_item)};

			images.push({
				renderItem: _a,
				renderThumbInner: _a,
				thumbnail: window.publicURL + props.src,
				// description: 'asdfasdfasdfasd fasdf asdf af af sd'
			});
		} else {
			_item.forEach(element => {
				images.push({
					original: window.publicURL + element,
					thumbnail: window.publicURL + element,
					renderItem: undefined,
					renderThumbInner: undefined,
					// description: 'asdfasdfasf'
				});
			});
		}
		
	} else {
		// _items = <>{_items}<img className='image-gallery-image !object-contain !w-1/2 h-full' src={window.publicURL + element}/></>

		images.push({
			original: window.publicURL + _item,
			thumbnail: window.publicURL + _item,
			renderItem: undefined,
			renderThumbInner: undefined,
			// description: 'asdfasdfasf'
		});
	}

	return _return;
}
function GroupImageItem(_item) {
	let _return = <></>;

	_item.forEach(_item => {
		_return = <>{_return}<img className='image-gallery-image !object-contain !w-1/2 h-full' src={window.publicURL + _item}/></>
	});

	_return = <>
	<div className='h-full w-full'>
		{_return}
	</div>
	</>;

	return _return;
}


	if (props.imageArray) ImageLoop(props.imageArray);

	var _moreImages = images.length > 1;


	var selectedImage = <>
		<div className='' style={{zIndex: '1000'}}>
			<div className='fixed h-screen top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
				<div className='relative w-full h-full'>
					<img key={'5'}
						className={`horizontal object-contain w-full h-full ${_type}`}
						alt={`Produc image`}
						src={window.publicURL + props.src}
					/>
					<div className={`cards_item_label_wrap horizontal ${_type}`}>
						<h2 className={`cards_item_label horizontal ${_type}`}>{_label}</h2>
					</div>
				</div>
			</div>
		</div>
	</>;

	var _image = <>
		<img key={'5'}
			className={`cards_item_img_contain2 horizontal ${_type}`}
			alt={`Produc image`}
			src={window.publicURL + props.src}
		/>
	</>;

	var _image_small = <>
		{/* <div> */}
			<img key={'5'}
				className={`${_type} m-3 border-round`}
				alt={`Produc image`}
				src={window.publicURL + props.src}
			/>
		{/* </div> */}
	</>;

	var _title = <>
		<div className='mx-3'><h1 className="container-title">{props.title}</h1>
		<HRLine className='!mb-0 mt-3' /></div>
	</>

	var _titleV = <>
		<div className='mx-3'><h1 className="container-title">{props.title}</h1>
		<HRLine className='mt-3' /></div>
	</>

    function ItemHorizontal() {
		return (<>
			<ContainerGroup>
				<ContainerWrapper>
					<Container style={{margin: '0'}} >
						<div  className='mb-1'>
{/* style={{height: '360px'}} */}
							<figure className={`cards_item_pic_wrap horizontal bgr bgr-big ${_type}`} data-category={_label}>
								{_label ? <div className={`cards_item_label_wrap horizontal ${_type}`}>
									<h2 className={`cards_item_label horizontal ${_type}`}>{_label}</h2>
									</div> : ""}
									
								{/* {_image} */}
								{/* <div style={{height: '360px'}}> */}
								{_multipleImage ? 
								<ImageGallery additionalClass={`image-gallery-horizontal`} showBullets={true} items={images} showThumbnails={_moreImages} showNav={_moreImages} showFullscreenButton={_moreImages} showPlayButton={_moreImages} />
								: <>{_image}</>
								}
								
								
								{/* </div> */}
								
							</figure>
						
						</div>
						{_title}
						<ContainerContent className='!pb-0'>
						
							{props.text}
						
						</ContainerContent>

						{/* <div className='flex flex-row justify-center h-52 mb-3'>
						
							{_image_small}
							{_image_small}
							{_image_small}
						
						</div> */}
						
					</Container>
				</ContainerWrapper>
				{/* <ContainerWrapper >
					<Container>
						<ContainerContent>


							
						</ContainerContent>
					</Container>
				</ContainerWrapper> */}
			</ContainerGroup>

		</>);
	}

	function ItemVertical() {
		return (<>
				<ContainerGroup style={{overflow: 'hidden'}} gap={{columnGap: "0%"}} background={true}>
					<ContainerWrapper>
						<Container background={false}>
							
							{_titleV}
							<ContainerContent className='!pb-0'>
							
								{props.text}
							
							</ContainerContent>

							{/* <div className='flex flex-row justify-center h-52'>
							
								{_image_small}
								{_image_small}
								{_image_small}
							
							</div> */}
							
						</Container>
					</ContainerWrapper>
					<ContainerWrapper width={_phone ? '100%' : '100%'}>
                                <figure className={`cards_item_pic_wrap horizontal bgr bgr-big ${_type}`} data-category={_label}>
                                    {_label ? <div className={`cards_item_label_wrap horizontal ${_type}`}>
                                        <h2 className={`cards_item_label horizontal ${_type}`}>{_label}</h2>
                                        </div> : ""}
                                        
									
                                    {_multipleImage ? 
                                    <ImageGallery additionalClass={'image-gallery-vertical'} items={images} thumbnailPosition={_thumbnailsBottom || _phone ? 'bottom' : 'left'}  showBullets={true} showThumbnails={_moreImages} showNav={_moreImages} showFullscreenButton={_moreImages} showPlayButton={_moreImages} />
                                    : <>{_image}</>
                                    }
                                    {/* {_image} */}
                                    
                                </figure>
						<Container background={false}>
							{/* <ContainerContent>
								
							</ContainerContent> */}
						</Container>
					</ContainerWrapper>
				</ContainerGroup>

			</>);
	}
// console.log(props.vertical);

	return <div className='mb-12'>
    <>{_phone ? ItemHorizontal() : (props.vertical ? ItemVertical() : ItemHorizontal())}</>
	{/* {selectedImage} */}

    </div>

}





export function CardItemHorizontalBCP(props) {

  var s = props.style;

  var _type = props.type ? props.type : '';

  var _logo_url = props.logo ? window.publicURL + props.logo : undefined;

	var _logoInImage = props.logoInImage;// Boolean
	
	var _phone = window.isPhoneSize;

	var _mirror = _phone ? true : props.mirror;

	var _label = _phone ? props.title : props.label;


	var _logo = _logo_url ?
		(<img key={'3'}
			className={`cards_item_logo-text horizontal ${_type}`}
			alt={`Project owners logo`}
			src={_logo_url}
		/>) : '';

	var _logo_image = _logo_url ?
		(<img key={'4'}
			className={`cards_item_logo-image horizontal ${_type}`}
			alt={`Project owners logo`}
			src={_logo_url}
		/>) : '';

	// Info
  var _text = 
	(<div key={'1'} className={`cards_item_half horizontal ${_type}`}>
		<div className={`cards_item_info horizontal ${_type}`}>
			<h2 style={{height: 0}} className={`cards_item_title horizontal ${_type}`}>{_phone ? '' : props.title}</h2>
			<p className={`cards_item_text horizontal ${_type}`}>{props.text ? (typeof props.text === 'string' ? parse(props.text) : props.text) : ""}</p>
			
			{!_logoInImage ? _logo : ''}
		</div>
	</div>
	);

  var _image = 
  	(<div key={'2'} className={`cards_item_half horizontal ${_type}`}>
	  <figure className={`cards_item_pic_wrap horizontal ${_type}`} data-category={_label}>
		{_label ? <div className={`cards_item_label_wrap horizontal ${_type}`}>
			<h2 className={`cards_item_label horizontal ${_type}`}>{_label}</h2>
			  </div> : ""}
			  
		{/* Product Image */}

		{/* Background */}
		{/* <img key={'5'}
			className={`cards_item_img_contain_bgr horizontal ${_type}`}
			alt={`Produc image`}
			src={window.publicURL + props.src}
		/> */}
			  
		<img key={'5'}
			className={`cards_item_img_contain2 border-round horizontal ${_type}`}
			alt={`Produc image`}
			src={window.publicURL + props.src}
		/>
			{_logoInImage ? _logo_image : ''}
		
	</figure>
	</div>
	);
  

  // if (props.zoom != undefined)
  // {
  //   var zoom = -(props.zoom / 6);
  //     //props.zoom = props.zoom / 100;
  //     s = {width: props.zoom + "%"};//{transform: "scale("+ (props.zoom / 100) + ")"}; , left: zoom + "%"
  //   }

  return (<>
	<div key={props._key} className={`cards_item horizontal ${_type} ${props.className}`} title="Project" style={props.style}> 
        <div className={`cards_item_link link_background ${_phone ? 'vertical' : 'horizontal'} ${_type}`}>

			{_mirror ? _image : _text}
			{_mirror ? _text : _image}

        </div>
      </div>
  </>);
}


// function ItemVerticalBCP() {
// 	var _logo = _logo_url ?
// 		(<img key={'3'}
// 			className={`cards_item_logo-text horizontal ${_type}`}
// 			alt={`Project owners logo`}
// 			src={_logo_url}
// 		/>) : '';

// 	var _logo_image = _logo_url ?
// 		(<img key={'4'}
// 			className={`cards_item_logo-image horizontal ${_type}`}
// 			alt={`Project owners logo`}
// 			src={_logo_url}
// 		/>) : '';

// 	// Info
//   var _text = 
// 	(<div key={'1'} className={`cards_item_half horizontal ${_type}`}>
// 		<div className={`cards_item_info horizontal ${_type}`}>
// 			<h2 style={{height: 0}} className={`cards_item_title horizontal ${_type}`}>{_phone ? '' : props.title}</h2>
// 			<p className={`cards_item_text horizontal ${_type}`}>{props.text ? (typeof props.text === 'string' ? parse(props.text) : props.text) : ""}</p>
			
// 			{!_logoInImage ? _logo : ''}
// 		</div>
// 	</div>
// 	);

//   var _image = 
//   	(<div key={'2'} className={`cards_item_half horizontal ${_type}`}>
// 	  <figure className={`cards_item_pic_wrap horizontal ${_type}`} data-category={_label}>
// 		{_label ? <div className={`cards_item_label_wrap horizontal ${_type}`}>
// 			<h2 className={`cards_item_label horizontal ${_type}`}>{_label}</h2>
// 			  </div> : ""}
			  
// 		{/* Product Image */}

// 		{/* Background */}
// 		{/* <img key={'5'}
// 			className={`cards_item_img_contain_bgr horizontal ${_type}`}
// 			alt={`Produc image`}
// 			src={window.publicURL + props.src}
// 		/> */}
			  
// 		<img key={'5'}
// 			className={`cards_item_img_contain2 border-round horizontal ${_type}`}
// 			alt={`Produc image`}
// 			src={window.publicURL + props.src}
// 		/>
// 			{_logoInImage ? _logo_image : ''}
		
// 	</figure>
// 	</div>
// 	);

//   return (<>
// 	<div key={props._key} className={`cards_item horizontal ${_type} ${props.className}`} title="Project" style={props.style}> 
//         <div className={`cards_item_link link_background ${_phone ? 'vertical' : 'horizontal'} ${_type}`}>

// 			{_mirror ? _image : _text}
// 			{_mirror ? _text : _image}

//         </div>
//       </div>
//   </>);
// }
