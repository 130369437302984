import React from 'react';

function Klanten () {
    return (
        <>
            <div className="page">
            Klanten
            </div>
        </>
    );
};

export default Klanten;