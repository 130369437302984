/*
In your main file "index.js" put this line:
import './config';

Everywhere you need your object use this:
global.config.companyInfo
*/
// import 'entities.js';


module.exports.global = global.config = {
    websiteInfo: {
        language: "nl"
        ,creationDate: "2024"
    }
    //global.config.companyInfo
    ,companyInfo: {
        name: "Schreuder Metaal & Techniek"
        ,abbreviation: ""
        ,establishedDate: new Date(2007, 9, 12)
        ,description: ""
        ,owner: "Dennis Schreuder"

        // asdf
        ,kvk: "37135730"
        ,btw: "NL002099916B18"

        ,businessHours: {
            maandag: ["7:30", "16:30"]
            ,dinsdag: ["7:30", "16:30"]
            ,woensdag: ["7:30", "16:30"]
            ,donderdag: ["7:30", "16:30"]
            ,vrijdag: ["7:30", "14:30"]
        }

        ,socials: {
            facebook: 'https://www.facebook.com/Schreudermetaalentechniek/'
            ,youtube: 'https://youtube.com/@SchreuderMetaalenTechnie-kb9rz?si=G0g-A1u56qJLBF7h'
            ,linkedin: 'https://linkedin.com/company/schreuder-metaal-en-techniek'
            ,instagram: 'https://www.instagram.com/schreuder_metaal_en_techniek/'
        }

        // Location
        ,country: "Nederland"
        ,address: {
            whole: "Perenmarkt 8" + ", " + "1681PG Zwaagdijk-Oost"
            ,street: "Perenmarkt"
            ,number: "8"
            ,city: "Zwaagdijk-Oost"
            ,stateProvince: "Noord-Holland"
            ,zipCode: "1681PG"
        }
        
        // Email's
        ,emailMain: "info@schreudermetaalentechniek.nl"
        ,emailExample: "email.example@example.com"
        ,emails: {
            offerte: "offerte@schreudermetaalentechniek.nl"
            ,dirkderoos: "dirk@schreudermetaalentechniek.nl"
            ,werknemer2: "two@schreudermetaalentechniek.nl"
        }

        // Phone numbers
        ,phoneNumberMain: "(+31) 228 52 66 21"
        ,phoneNumbers: {
            phoneNumber01: "(+31) 228 52 66 21"
            ,dirkderoos: "(+31) 228 52 66 21"
            ,werknemer2: "(+31) 228 52 66 21"
        }
    }
    ,companyMedia: {
        logo: "/logo/logo_SchreuderMetaalEnTechniek_300dpi.png"
        ,logoWhite: "/logo/logo_SchreuderMetaalEnTechniek_White_300dpi.png"
    }
    ,authorInfo: {
        name: "Peter Schreuder"
        ,emailMain: ""
        ,phoneNumberMain: ""
        ,website: ""
    }
	,placeholder: {
		image: "/images/placeholder/no-image-placeholder.jpg"
	}
}



/*

,variable: ""

*/