import React from "react";
// import { Link } from 'react-router-dom';
// to={props.path}
export default function CardItemVertical(props) {
  var zoom; // = {height: "100%"};
  
  var wrap = { height: "70vh", paddingTop: "67%", transition: "all 0.5s ease" };

  if (props.active) {
    wrap = { ...wrap, height: "15vh", paddingTop: "0" };
  }

  if (props.zoom != undefined)
  {
    var zoom = -(props.zoom / 6);
      //props.zoom = props.zoom / 100;
      zoom = {width: props.zoom + "%", margin: "auto auto"};//{transform: "scale("+ (props.zoom / 100) + ")"}; , left: zoom + "%"
    }

  const onClick = () => {props.handleClick({click: true, path: props.title})};

  return (
    <>
      <li key={props.title} className="cards_vertical_item" title={`Open: ${props.title}`}>
        <div className="cards_vertical_item_link">
          {/* Info */}
          <div className={`cards_vertical_item_info ${props.active? "" : "vertical_info_idle"}`}>
            <h5 className={`cards_vertical_item_title ${props.active? "" : "vertical_title_idle"}`}>{props.title}</h5>
            {/* <p className='cards_vertical_item_text'>{props.text}</p> */}
          </div>

          <figure
            className="cards_vertical_item_pic_wrap"
            style={wrap}
            data-category={props.label}
            onClick={onClick}
          >
            <img
              className="cards_vertical_item_img"
              alt="Image"
              src={window.publicURL + props.src}
              style={zoom}
            />
          </figure>
        </div>
      </li>
    </>
  );
}
