import React from 'react';
import { Link } from 'react-router-dom';

import parse from 'html-react-parser';


var iconPhone = '<i className="fas fa-phone-alt"></i>';
var iconMail = '<i className="fas fa-envelope"></i>';
var iconLocation = '<i className="fas fa-map-marker-alt"></i>';

export function InfoPhone(props) {
  return (
    Info([
      global.config.companyInfo.phoneNumberMain,
      global.config.companyInfo.emails.email01
    ]
    ,props.index
    ,"fas fa-phone-alt"
    )
  )
}

export function InfoEmail(props) {
  return (
    Info([
      global.config.companyInfo.emailMain,
      global.config.companyInfo.emails.email01
    ]
    ,props.index
    ,"fas fa-envelope-square"
    )
  )
}

export function ShowPhone(props) {
  var _value = props.phone;//global.config.companyInfo.phoneNumberMain;
  var _className = props.className ? props.className : `font-p text-accentDarker`;
  return (<>
    <span>{parse(iconPhone)} <a className={`${_className}`} href={`tel:${_value}`}>{_value}</a></span>
  </>)
}

export function ShowEmail(props) {
  var _value = props.email;//global.config.companyInfo.emailMain;
  var _className = props.className ? props.className : `font-p text-accentDarker`;

  return (<>
    <span>{parse(iconMail)} <a className={`${_className}`} href={`mailto:${_value}`}>{_value}</a></span>
  </>)
}

export function ShowLocation(props) {
  var _value = props.location;//global.config.companyInfo.emailMain;
  var _url = props.url;//global.config.companyInfo.emailMain;
  var _className = props.className ? props.className : `font-p text-accentDarker`;
  return (<>
    <span>{parse(iconLocation)} <a target='blank' className={`${_className}`} href={`${_url}`}>{_value}</a></span>
  </>)
}

export function InfoLocations(props) {
  return (
    Info([
      global.config.companyInfo.address.whole
    ]
    ,props.index
    ,"fas fa-map-marker-alt"
    )
  )
}

export function Info(array, index, marker) {
  const _array = array;
  var _value = _array[index];

  if (!_value) {
    console.error(`No value found with index: ${index}`);
    return <div></div>

  } else {

    return (
      <div><i className={marker}></i> <a>{_value}</a></div>
    );
  }
}

// export function InfoEmail(props) {

//   const emailArray = [
//     global.config.companyInfo.emailMain,
//     global.config.companyInfo.emails.email01
//   ]

//   var email = emailArray[props.index];

//   if (!email)
//   {
//     console.error("No email found with index: " + props.index);
//     return <div></div>
//   }
//   else
//   {
//     return (
//       <div><i className="fas fa-envelope-square"></i> <a>{email}</a></div>
//     );
//   }
// }

// export function InfoLocations(props) {

//   const array = [
//     global.config.companyInfo.address
//   ]

//   var value = array[props.index];

//   if (!value)
//   {
//     console.error("No address found with index: " + props.index);
//     return <div></div>
//   }
//   else
//   {
//     return (
//       <div><i className="fas fa-map-marker-alt"></i> <a>{value}</a></div>
//     );
//   }
// }

