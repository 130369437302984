import React from 'react';
import HeaderSection from '../layout/pages/home/HeaderSection';
import CompanyStory from '../layout/text/CompanyStory';
import Cards from '../layout/cards/Cards';
import CardsHorizontal from '../layout/cards/CardsHorizontal';
import { importAll, importAllArray } from 'images';

import {entities} from 'entities.js';
import Gallery from 'components/layout/media/Gallery';
import { Container, ContainerContent, ContainerWrapper, ContainerGroup } from 'components/layout/containers/Containers';
import { ArrayToHTMLList } from 'components/tools/arrays';
import { P } from 'styling/Text';
import { List } from 'components/layout/text/TextComponents';


const story = undefined;
const title = `Assemblage`;

export default function Assembly () {
	let _video = `${process.env.PUBLIC_URL}/videos/video-1.mp4`;
	let _image = `${process.env.PUBLIC_URL}/images/portfolio/layout/pages/home/20210526_121644.jpg`;

	const _imageArray = importAllArray(
		require.context('../../../public/images/portfolio/layout/pages/assembly', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
        ,'images/portfolio/layout/pages/assembly'
	);

    let _montagetechnieken = [
        'Mechanisch',
        'Pneumatisch',
    ];

    let _disciplines = [
        'Reinigen (o.a. ultrasoon)',
        'Testen/valideren',
        'Geconditioneerde meetruimte'
    ];

    return (
        <>
            <div className="page">
                {/* <HeaderSection video={'/videos/video-1.mp4'} showInfo={true} logo={true} /> */}
                {/* <HeaderSection video={_media} showInfo={true} logo={true} /> */}
                {/* <HeaderSection image={_image} showInfo={true} logo={true} /> */}
                <HeaderSection title={`${title}`} mediaHeight={'450px'} imageArray={_imageArray} showInfo={false} logo={false} />

                <div className="page-content">
                    <CompanyStory title={`${title}`}>
                        <P>
                            Bij alleen toeleveren van draai- en freesdelen voor onze clientèle blijft er ook vaak de vraag om verder ontzorgd te worden. Hierin kunnen wij ook ruim faciliteren.
                            Wij hebben een goed ingerichte montageruimte om projectmatig of seriematig af te werken of te assembleren met onze medewerkers.
                        </P>
                        <P>
                            Voordelen van montage binnen Schreuder Metaal en Techniek zijn zodanig dat met name bij pilotprojecten er direct gehandeld kan worden als er onverhoopt wijzigingen gemaakt dienen te worden.<br/>
                            Hierin vinden wij het belangrijk dat de lijntjes kort blijven en we niet direct van derden afhankelijk zijn.
                        </P>
                    </CompanyStory>

                    <ContainerGroup style={{alignItems: 'stretch'}}>
                        <ContainerWrapper className={'item'}>
                            <h1 style={{ width: '100%'}} className="container-title">Montagetechnieken:</h1>
                            <Container className='container-content-list'>
                                <ContainerContent>
                                    {/* <ul style={{padding: '15px'}}>
                                        {_montagetechnieken}
                                    </ul> */}

                                    <List style={{padding: '15px'}}
                                        // subTitle={"sadf"}
                                        items={[..._montagetechnieken
                                        ]}
                                    />

                                </ContainerContent>
                                <ContainerContent className={'container-image'}>
                                    <img src={`${window.publicURL}/images/portfolio/layout/pages/assembly/IMG-20200924-WA0015 (Medium).jpg`}></img>
                                </ContainerContent>
                            </Container>
                        </ContainerWrapper>

                            {/* <div ><p style={{textAlign: 'center'}}>Neem contact met ons op</p></div> */}

                        <ContainerWrapper className={'item'}>
                            {/* style={{textAlign: 'center'}} */}
                            {/* <h1  className="container-title">Overige disciplines:</h1>  */}
                            <h1  className="container-title">Overige dienstverlening:</h1> 
                            <Container className='container-content-list'>
                                <ContainerContent>
                                    {/* <ul style={{padding: '15px'}}>
                                        {_disciplines}
                                    </ul> */}
                                    <List style={{padding: '15px'}}
                                        // subTitle={"sadf"}
                                        items={[
                                            ..._disciplines
                                        ]}
                                    />
                                </ContainerContent>
                                <ContainerContent className={'container-image'}>
                                    <img src={`${window.publicURL}/images/portfolio/layout/pages/contact/meetkamer-01E (Medium).jpg`}></img>
                                </ContainerContent>
                            </Container>
                        </ContainerWrapper>
                    </ContainerGroup>
                    
					{/* <Gallery path={process.env.PUBLIC_URL}/> */}
                    <hr/>

                    {/* <CardsHorizontal title={"Een selectie van onze projecten"} _key={1123} projects={entities.companies.SchreuderMetaalEnTechniek.GetProjects()} cards={undefined} /> */}
                
                
                </div>
            </div>
        </>
    );
};

function AssemblyStory() {
    return (<>
    
    </>)
}