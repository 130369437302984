import React from 'react';
import HeaderSection from '../layout/pages/home/HeaderSection';
import CompanyStory from '../layout/text/CompanyStory';
import Cards from '../layout/cards/Cards';
import CardsHorizontal from '../layout/cards/CardsHorizontal';
import { Slideshow, importAll, importAllArray } from 'images';

import {entities} from 'entities.js';
import Gallery from 'components/layout/media/Gallery';
import { Container, ContainerContent, ContainerWrapper, ContainerGroup } from 'components/layout/containers/Containers';
import { ArrayToHTMLList } from 'components/tools/arrays';
import { P } from 'styling/Text';
import { InformationFollows } from 'components/layout/text/TextComponents';


const story = undefined;
const title = `Productie`;

export default function Production () {
	let _video = `${process.env.PUBLIC_URL}/videos/video-1.mp4`;
	let _image = `${process.env.PUBLIC_URL}/images/portfolio/layout/pages/home/20210526_121644.jpg`;

	const _imageArray = importAllArray(
		require.context('../../../public/images/portfolio/layout/pages/production', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
        ,'images/portfolio/layout/pages/production'
    );

    let _montagetechnieken = [
        'Mechanisch',
        'Pneumatisch'
    ];
    _montagetechnieken = ArrayToHTMLList(_montagetechnieken, 'list-item');

    let _disciplines = [
        'Reinigen (o.a. ultrasoon)',
        'Testen/valideren',
        'Geconditioneerde meetruimte'
    ];
    _disciplines = ArrayToHTMLList(_disciplines, 'list-item');

    return (
        <>
            <div className="page">
                {/* <HeaderSection video={'/videos/video-1.mp4'} showInfo={true} logo={true} /> */}
                {/* <HeaderSection video={_media} showInfo={true} logo={true} /> */}
                {/* <HeaderSection image={_image} showInfo={true} logo={true} /> */}
                <HeaderSection title={`${title}`} mediaHeight={'450px'} imageArray={_imageArray} showInfo={false} logo={false} />

                <div className="page-content">
                    <CompanyStory title={"Hight mix, low volume"}>
                        <P>
                        Zoals vele machinefabrieken draaien en frezen, hebben wij bij Schreuder Metaal en Techniek altijd wel een meerwaarde weten te geven aan het vak van machinebankwerken.
                            Dit komt tot stand door geregeld "out of the box'' te moeten denken en handelen met als bijkomend voordeel dat er heel veel praktisch inzicht is om ook de meest bijzondere werkstukken te mogen maken.
                        </P>
                        <P>
                        De kennis die hiermee opgedaan is geven wij direct door aan onze medewerkers zodat die pragmatisch en probleemoplossend de onderdelen voor u kunnen maken.
                        Of het nu enkelstuks, prototyping of seriewerk betreft, met alles wat wij maken zit bezieling in.
                        </P>
                    </CompanyStory>

                    {/* <CompanyStory title={"In batches produceren"}>

                    </CompanyStory> */}

                    {InformationFollows()}

                    
                    
					{/* <Gallery path={process.env.PUBLIC_URL}/> */}
                    {/* <CardsHorizontal _key={1123} projects={entities.companies.SchreuderMetaalEnTechniek.GetProjects()} cards={undefined} /> */}
                </div>
            </div>
        </>
    );
};

function AssemblyStory() {
    return (<>
    
    </>)
}