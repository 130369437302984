import CardsVertical from 'components/layout/cards/CardsVertical';
import CompanyStory from 'components/layout/text/CompanyStory';
import React from 'react';
import { P } from 'styling/Text';

export default function Machinepark() {
    const _story = <>
    <CompanyStory title={'Alles begint met goed gereedschap'} noMarginBottom>
                    <P>
                        Om onze kwaliteitsnormen en hoogwaardige afwerkingen te borgen en continueren, blijven wij investeren in ons machinepark en gereedschappen.
                    </P>
                    <P noBreak>
                        Wij laten graag zien met welke machines en gereedschappen wij zoal opereren met de bijbehorende specificaties en bereiken.
                    </P>
                    </CompanyStory>
    </>

    return (
        <>
            <div className="page">

                <div className='page-content'>
                    

                    {/* <div>
                        <h1 className='center width100'>Alles begint met goed gereedschap</h1>
                        <br/>
                        <P>Om onze kwaliteitsnormen en hoogwaardige afwerkingen te borgen en continueren, blijven wij investeren in ons machinepark en gereedschappen.</P>
                        <P noBreak>Wij laten graag zien met welke machines en gereedschappen wij zoal opereren met de bijbehorende specificaties en bereiken.</P>

                    </div> */}

                    <CardsVertical />
                    <hr/>

                    {_story}
                </div>

            </div>
        </>
    );
};


/*
<div style={{ display: "flex", flexDirection: "row", height: "70vh" }}>

                        <div style={{ width: "100%", overflow: "hidden" }}>
                            <img width="100%" height="100%" style={{ transform: "scale(1.5)", float: "left", objectFit: "cover",  }} src='/images/portfolio/custom-laswerk/custom-laswerk-as-01.jpeg'></img>
                        </div>
                        <div style={{ width: "100%", overflow: "hidden" }}>
                            <img width="100%" height="100%" style={{ transform: "scale(1)", float: "left", objectFit: "cover", objectPosition: "50%" }} src='/images/portfolio/custom-laswerk/custom-laswerk-as-01.jpeg'></img>
                        </div>
                        <div style={{ width: "100%", overflow: "hidden" }}>
                            <img width="100%" height="100%" style={{ transform: "scale(1)", float: "left", objectFit: "cover", objectPosition: "50%" }} src='/images/portfolio/custom-laswerk/custom-laswerk-as-01.jpeg'></img>
                        </div>
                    </div>
*/