import React from 'react';
import '../../../../App.css'
import { Button } from '../../interacion/button/Button';
import { InfoEmail, InfoLocations, InfoPhone } from './company_info/Info';
import './HeaderSection.css';
import { Slide, Fade  } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { Slideshow, importAll, importAllArray } from 'images';
// import { InfoEmail, InfoLocations } from './company_info/InfoEmail';
// import  from './company_info/InfoLocations';

export default function HeaderSection(props) {
	
	let _mediaHeight = props.mediaHeight || "100%";

	let _foreground = props.foreground == undefined ? true : false;
	
	let _mid = 70;
	let _mid2 = 45;

	let _min = 25;
	let _max = 80;
	let _bgr = `linear-gradient(to right, rgba(0, 0, 0, ${_max}%) 0%, rgba(0, 0, 0, ${_min}%) ${_mid2}%, rgba(0, 0, 0, ${_min}%) ${_mid}%,rgba(0, 0, 0, ${_max}%) 100%)`;

	const fadeImages = props.imageArray;
	// console.log(fadeImages);
	//  importAllArray(
	// 	require.context('../../../../../public/images/portfolio/layout/pages/home/', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
	// );
	
	  //style={{height: props._mediaHeight}} 
	  

//, from-transparent
    return (<>
        <div className='hero-container-wrapper'>
            <div className='hero-container'>
                {/* <img className="background" src="./images/img-header-spanen.jpeg"></img> */}

                <div className='hero-content' style={{height: _mediaHeight}}>
                <div className='colored-background bgr bgr-big '></div>
					<div className='hero-media-wrapper'>

						{props.video != undefined ?
						<video className="header-media header-video" style={{height: _mediaHeight}} src={props.video} autoPlay loop muted />
						: ""}
						
						{props.image != undefined ?
						<>
						<img className="header-media header-image" style={{height: _mediaHeight}} src={props.image}></img> 
						{/* <div style={{height: "17vw"}}></div> for a moving navbar */}
						</>
						: ""}

						{props.imageArray != undefined ?
						<>
						<div className='slideshow-container'><Slideshow className={'header-media'} images={fadeImages} mediaHeight={_mediaHeight}/></div>
						</>
						: ""}

						{_foreground ? <div className='header-media-foreground' style={{background: _bgr, height: _mediaHeight}}></div> : ""}

					</div>

                    {props.logo ? <img className="header-logo" src={window.publicURL + global.config.companyMedia.logoWhite}></img> : ""}
                    {/* {props.logo ? <img className="header-logo" src={global.config.companyMedia.logoWhite}></img> : ""} */}

					{props.title ? <div className='hero-content-title-wrapper'>
						<h1 className='hero-content-title'>{props.title}</h1>
					</div> : ''}

                    {/* <p>Generic slogan here</p> */}
                    
                    {/* <div className="hero-btns">
                        <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large'>PORTFOLIO</Button>
                        <Button className='btns' buttonStyle='btn--primary' buttonSize='btn--large'>OFFERTE AANVRAGEN <i className="fas fa-arrow-circle-right arrow"></i></Button>
                    </div> */}

                    {(props.showInfo != undefined && props.showInfo == true) ? 
                    <div className="contact-info-wrapper">
                        <ul className="contact-info">
                            <li><InfoPhone index='0' /></li>
                            <li><InfoEmail index='0' /></li>
                            {/* <li><InfoEmail index='1' /></li> */}
                            <li><InfoLocations index='0' /></li>
                        </ul>
                    </div>
                     : ""}
                </div>
            </div>
        </div>
    </>)
}




//   const fadeImages = [
// 	{
// 	  url: 'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
// 	  caption: 'First Slide'
// 	},
// 	{
// 	  url: 'https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80',
// 	  caption: 'Second Slide'
// 	},
// 	{
// 	  url: 'https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
// 	  caption: 'Third Slide'
// 	},
//   ];

//   const Slideshow = (props) => {
// 	console.log(props._mediaHeight);
// 	return (
// 	  <div className="slide-container header-media">
// 		<Fade infinite={true} style={{height:'100%'}}>
// 		  {fadeImages.map((fadeImage, index) => (
// 			<div  key={index}>
// 			  <img className='header-slideshow' style={{ width: '100%'}} src={fadeImage.url} />
// 			  <h2>{fadeImage.caption}</h2>
// 			</div>
// 		  ))}
// 		</Fade>
// 	  </div>
// 	)
//   }