import { P } from 'styling/Text';
import './config';

let newsItems = {};
export default newsItems;
export {default as newsItems} from 'newsItems.js';

newsItems.companies = {};

const basicText = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.";

// --- Entity Classes ---

export class Entity {
	constructor(props) {
		this.active = props.active || true;
		this.name = props.name;
		this.role = props.role;
		this.logo = props.logo || ImageSwitch(global.config.placeholder.image);// can also be a profile picture
		this.emails = props.emails;
		this.phoneNumbers = props.phoneNumbers;

		return {
			 active: this.active
			,name: this.name
			,role: this.role
			,logo: this.logo
			,emails: this.emails
			,phoneNumbers: this.phoneNumbers
		};
	}
};

export class Company extends Entity {
	constructor(props) {
		super(props);
		this.kvk = props.kvk;
		this.btw = props.btw;

		// - NewsItemss
		this.projects = [];

		this.GetNewsItemsAmount = function() {
			return this.projects.length;
		}

		this.AddNewsItem = function(_project, _owner = new InfoList()) {
			if (!_owner.dataObjects) _project.owner = new InfoList(this);// If no owner is given. Set the default to this
			
			this.projects.push(_project);
		}

		this.RemoveNewsItem = function(_project) {
			this.projects.push(_project);
		}

		this.GetNewsItems = function() {
			return this.projects;
		}

		this.GetNewsItemsByIndex = function(_index) {
			return this.projects[_index];
		}

		this.GetNewsItemsByName = function(_name) {
			let _return = undefined;

			this.projects.forEach(item => {
				if (item.name) {
					_return = item;
					return false;
				}
			});

			return _return;
		}
	}
}

// --- NewsItemss ---

export class NewsItems {
	constructor(props) {
		this.owner = props.owner;
		this.name = props.name || "Example NewsItems";
		this.description = props.description || (basicText + basicText + basicText);
		this.label = props.label;
		this.date = props.date;
		this.image = props.image;
		this.video = props.video;
		this.youtube = props.youtube;
		this.imageArray = props.imageArray;
		this.vertical = props.vertical;
		this.subText = props.subText;
	}
}

// --- Utilities ---AddNewsItems
export class InfoList {
	constructor(..._dataObjects) {
		this.dataObjects = undefined;

		if (Array.isArray(_dataObjects)) {
			this.dataObjects = _dataObjects;
			// If there is only one instance
			// if (_dataObjects.length == 1) {
			// 	// this.dataObjects = [];
			// 	this.dataObjects = String(_dataObjects[0]);
			// }
			// console.log(this.dataObjects, _dataObjects.length);
		}		

		return this.dataObjects;
	}
}

// --- Companies ---
let _company;

newsItems.companies.SchreuderMetaalEnTechniek = new Company({
	name: 'Schreuder Metaal & Techniek'
	,logo: ImageSwitch('logo/logo_SchreuderMetaalEnTechniek_300dpi.png', 'logo/logo_SchreuderMetaalEnTechniek_White_300dpi.png')
	,emails: new InfoList('info@schreudermetaalentechniek.nl')
	,phoneNumbers: new InfoList('(+31) 228 52 66 21')
});
_company = newsItems.companies.SchreuderMetaalEnTechniek;
// _company.AddNewsItems(new NewsItems({
// 	name: "Test NewsItems"
// 	,description: <><P>Test Discription</P></>
// 	,image: '/images/portfolio/projects/bronzen-giektop/Eindresultaat Bronzen Giektop.jpg'
//  ,imageArray: [
// 		['/images/portfolio/projects/oudste-tram/tram_12.jpg', '/images/portfolio/projects/oudste-tram/tram_11.jpg']
// 		,'/images/portfolio/projects/oudste-tram/tram_2.jpg'
// 		,'/images/portfolio/projects/oudste-tram/tram_3.jpg'
// 	]
// }));

// Test

const _imgTest = '/images/portfolio/projects/oudste-tram/tram_11.jpg';
const _textTest = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.";
const formatter = new Intl.DateTimeFormat('nl', { month: 'long' });
function _Date(_dateString) {
	// return formatter.format(new Date(_dateString));
	return new Date(_dateString);
}

_company.AddNewsItem(new NewsItems({
	name: "Schreuder Metaal & Techniek Opgericht"
	,date: _Date('2007-8')
	,subText: "Oprichting van het bedrijf"
	,description: <>
		<P>Dennis Schreuder is hier voor zichzelf begonnen als ZZP'er.<br/>Gespecialiseerd in verspaning, reparatie, onderhoud en revisie.</P>
	</>
	,image: '/images/company/news/opgericht_2007.jpg'
}));

_company.AddNewsItem(new NewsItems({
	name: "Aanschaf Spinner"
	,date: _Date('2014-11')
	,subText: undefined
	,description: <>
		<P>Bij een gestage uitbreiding van onze klantenkring kwam ook de behoefte om meer te automatiseren. Dan ga je knopen hakken en staat er zomaar een veel te grote draaibank in je te kleine werkplaats.</P>
	</>
	,image: '/images/company/news/aanschaf_spinner.jpg'
}));

_company.AddNewsItem(new NewsItems({
	name: "Komst Cinch Mill"
	,date: _Date('2009-6')
	,subText: undefined
	,description: <>
		<P>Eerste aanschaf hagelnieuwe freesbank. Eerste stap van conventioneel naar CNC bewerken.</P>
	</>
	,image: '/images/company/news/komst_cinchmill.jpg'
}));

_company.AddNewsItem(new NewsItems({
	name: "Verhuizing Perenmarkt"
	,date: _Date('2016-1')
	,subText: undefined
	,description: <>
		<P>Capaciteits uitbreiding vanwege toename naar vraag van kwaliteits onderdelen. Van 120m<sup>2</sup> naar 600m<sup>2</sup></P>
	</>
	,image: '/images/company/news/verhuizing_perenmarkt.jpg'
}));

_company.AddNewsItem(new NewsItems({
	name: "Komst Finetech"
	,date: _Date('2016-11')
	,subText: undefined
	,description: <>
		<P>Uitbreiding capaciteit in complexer freeswerk voor betere aanbod naar onze klantenkring.</P>
	</>
	// ,image: '/images/company/news/komst_finetech.mp4'
	// ,video: '/images/company/news/komst_finetech.mp4'
	// ,youtube: 'https://youtu.be/embed/6Wz_6WT9X28'
	,youtube: 'https://www.youtube.com/embed/6Wz_6WT9X28?si=tSoS-HBSiAaGt6tg'
}));

_company.AddNewsItem(new NewsItems({
	name: "Komst TOS"
	,date: _Date('2019-5')
	,description: <>
		<P>Aangeschaft door vraag naar groot draaiwerk.</P>
	</>
	// ,video: '/images/company/news/komst_tos.mp4'
	,youtube: 'https://www.youtube.com/embed/FQrqMlD6aGw?si=krSGwxLaZO9NcTdm'
}));

_company.AddNewsItem(new NewsItems({
	name: "15 Jarig bestaan"
	,date: _Date('2022-9')
	,description: <>
		<P>Na jaren hard aan de weg timmeren bereik je als bedrijf toch een 15 jarig jubileum.</P>
	</>
	,image: '/images/company/news/15_jarig_bestaan.jpg'
}));

_company.AddNewsItem(new NewsItems({
	name: "Uitbreiding Machinepark"
	,date: _Date('2023-5')
	,description: <>
		<P>Fehlmann Freesbank, Jacobsen Vlakslijper, Hauser Meetmachine</P>
		{/* <P>Nieuwe toevoeging aans ons freesbank arsenaal.</P> */}
	</>
	,image: '/images/company/news/komst_fehlmann.jpg'
}));

_company.AddNewsItem(new NewsItems({
	name: "Vraag & Aanbod"
	,date: _Date('2024-3')
	,description: <>
		<P>Heeft ons geinterviewd voor hun stuk over "Investering in de toekomst".</P>
		<P>Te lezen hier: <a target='blank' href='https://www.vraagenaanbod.nl/achtergrond/investering-in-de-toekomst/'>Vraag & Aanbod</a></P>
	</>
	,image: '/images/company/news/vraag_en_aanbod.jpg'
}));

_company.AddNewsItem(new NewsItems({
	name: "Rick Schreuder 10 jarig jubileum"
	,date: _Date('2024-5')
	,description: <>
		<P>Hieperdepiep, Rick Schreuder is vandaag jubilaris! 10 jaar geleden begonnen bij Schreuder Metaal en Techniek.</P>
		<P>Rick, heel veel dank voor je geweldige bijdrage deze afgelopen 10 jaar. Wij als team vinden je een topper. Nog heel veel  mooie jaren bij de groei van ons unieke bedrijf.</P>
	</>
	,image: '/images/company/news/rick_schreuder_10jaar.jpg'
}));

// _company.AddNewsItem(_company.GetNewsItemsByIndex(0));
// _company.AddNewsItem(_company.GetNewsItemsByIndex(0));
// _company.AddNewsItem(_company.GetNewsItemsByIndex(0));

// --- Functions & Data ---

// export function InfoList(...dataObjects) {
// 	let data = dataObjects;
	
// 	return data;
// };

export function ImageSwitch(...dataArray) {
	return function(_value) {
		return _value ? dataArray[_value] : dataArray[0];
	};
}


