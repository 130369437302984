import React from 'react';
import './Socialmedia.css';
import { Container, ContainerContent, ContainerWrapper } from 'components/layout/containers/Containers';
import { Link } from 'react-router-dom';

export default function Socialmedia(props) {
	return (<>
		<ContainerWrapper>

			<Container background={false}>
				<ContainerContent  style={{padding: 0, paddingTop: "10px"}}>
					<div className='so-content'>
						<SOLink place='facebook' color='#4267B2' big={true} to={`${global.config.companyInfo.socials.facebook}`} />
						<SOLink place='youtube' color='red' big={true} to={`${global.config.companyInfo.socials.youtube}`} />
						<SOLink place='linkedin' color='#0e76a8' big={true} to={`${global.config.companyInfo.socials.linkedin}`} />
						<SOLink place='instagram' textColor='#fbad50' big={true} to={`${global.config.companyInfo.socials.instagram}`} />
					</div>
				</ContainerContent>
			</Container>
		</ContainerWrapper>
		<br />
	</>)
}

export function SOLink(props) {
	const _place = props.place;
	const _placeCap = String(_place).charAt(0).toLocaleUpperCase() + String(_place).slice(1);
	const _linkStyle = { textDecoration: "none" };

	if (props.color) {
		Object.assign(_linkStyle, { color: String(props.color) });
	}

	const _textStyle = { fontSize: "min(max(10px, 5vw), 15px)", textDecoration: "none" };

	if (props.textColor) {
		Object.assign(_textStyle, { color: String(props.textColor) });
	}

	return (<>
		{/* <div> */}
			<a className={`social-icon-link ${_place} text-center`} style={{ ..._linkStyle }}
				href={props.to}
				target="_blank"
				aria-label={`${_placeCap}`}
			>
				
				<div>
					<i  className={`fab fa-${_place}`} style={props.big == true ? { fontSize: "min(max(100px, 20vw), 70px)", textAlign: "center"} : {}}>
					</i>
				</div>
				{props.big == true ? <h3 className='text-center' style={_textStyle}>{`${_placeCap}`}</h3> : ''}

			</a>
		{/* </div> */}
	</>)
}

{/* <div className='container-wrapper so-container-wrapper'>
            <div className='so-container'>
                <div className='so-content'>
                    asdf
                </div>
            </div>
        </div> */}