import React from 'react'
import CardItem from './CardItem';
import './Cards.css';

var before = 'Custom ';
var after = '';

function TitleText(string) {
  return before + string + after
}

export default function CardsCarousel(props) {
    let textTitle = props.title;

  const _cards = props.cards;

  const _card_amount = _cards.length;
// console.log(_card_amount);

  return (<>
    <div className='slider-body'>
      <div className='slider'>
        <div className='slide-track' style={{width: `calc(250px * ${_card_amount * 2})`}}>
          {_cards}
          {_cards}
        </div>
      </div>
    </div>
  </>);
  }
