import PartnerCards from 'components/layout/PartnerCards';
import CardItem from 'components/layout/cards/CardItem';
import Cards from 'components/layout/cards/Cards';
import CardsCarousel from 'components/layout/cards/CardsCarousel';
import CardsHorizontal from 'components/layout/cards/CardsHorizontal';
import CardsVertical from 'components/layout/cards/CardsVertical';
import { Container, ContainerContent, ContainerGroup, ContainerWrapper } from 'components/layout/containers/Containers';
import HeaderSection from 'components/layout/pages/home/HeaderSection';
import CompanyStory from 'components/layout/text/CompanyStory';
import { importAllArray } from 'images';
import React from 'react';
import { P, Title } from 'styling/Text';





// window.publicURL + '/images/portfolio/layout/pages/contact/meetkamer-01E (Medium).jpg'
export default function About() {
    const _imageArray = importAllArray(
		require.context('../../../public/images/portfolio/layout/pages/home', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
        ,'images/portfolio/layout/pages/home'
    );
    
    

    return (
        <>
            <div className="page">
                <HeaderSection title={"Over Ons"} mediaHeight="40vh" imageArray={_imageArray} showInfo={false} />
                <div className='page-content'>

                    <CompanyStory line={false}>
                        <br/>
                        <P>
                            Van eenmanszaak opgericht in 2007 tot een sterk en ambitieus team van jonge vaklieden wat vandaag Schreuder Metaal en Techniek maakt.
                            Bij ons staan kwaliteit én tevredenheid vooraan.
                        </P>
                        <P>
                            Als team kijkt Schreuder Metaal en Techniek ook voortdurend vooruit naar de nieuwste innovaties om u en uw branche altijd te kunnen helpen.
                            Onze kennis gaat gepaard met de nieuwste gereedschappen en veelzijdige bewerkingsmachines.
                        </P>
                    </CompanyStory>
                    
                    <ContainerWrapper style={{animationName: "from-bottom, from-transparent", animationDuration: "1.4s"}}>
                        <Container background={false}>
                            <ContainerContent>
                                    
                                <div style={{ paddingLeft: "2%" }}>
                                    

                                    <ContainerGroup gap={{columnGap: "5%"}}>
                                        <ContainerWrapper className={'item'}>
                                            <h1 className="container-title">Missie:</h1>
                                            <Container>
                                                <ContainerContent>
                                                <P>Wij hebben onze kwaliteitsnormen en servicegerichtheid hoog op onze agenda staan, dit hebben wij de afgelopen jaren met zorg opgebouwd en streven ernaar dit continue te verbeteren en vast te houden.</P>

                                                <P>Naast onze hoge kwaliteitszorg vinden wij het belangrijk om onze communicatie en bereikbaarheid zo toegankelijk mogelijk te houden en waken over onze leverbetrouwbaarheid door een strak georganiseerde planning.</P>

                                                <P>Mede door ons bevlogen team kunnen wij snel omschakelen en hebben wij een stabiel platform gecreëerd dat wij ruimte kunnen maken om met onze klanten mee te denken in zowel enginering als de slimste oplossingen voor het maken van hun product of project.</P>
                                                </ContainerContent>
                                            </Container>
                                        </ContainerWrapper>

                                        <ContainerWrapper className={'item'}>
                                            <h1  className="container-title">Visie:</h1> 
                                            <Container>
                                                <ContainerContent>
                                                    <P>‘Van Protofase tot Serieproductie’</P>

                                                    <P>Wij bieden beide mogelijkheden en ontzorgen op vele vlakken met de kwaliteit en service die verwacht mag worden. </P>

                                                    <P>Een snelle reactie op offerteaanvragen en het bieden van maatwerk waar een betreffend project om vraagt.</P>

                                                    <P>Meegaan met de laatste Verspaningstechnieken om onze klanten ten alle tijden van de beste mogelijkheden van dienst te kunnen zijn.</P>

                                                    <P>Met behulp van onze brede netwerk de laatste ontwikkelingen op gebied van materialen en gereedschappen bijhouden om nog beter onze klanten te kunnen adviseren over de toepassingen voor hun product of project.</P>

                                                    <P>Het uitbreiden van ons netwerk op alle vlakken en een nauwe samenwerking onderhouden met onze klanten en leveranciers.</P>

                                                    <P>Meedenken met de klant op gebied van maakbaarheid, ontwerp en enginering.</P>

                                                </ContainerContent>
                                            </Container>
                                        </ContainerWrapper>
                                    </ContainerGroup>
                                    

                                    </div>
                            </ContainerContent>
                        </Container>
                    </ContainerWrapper>
                                    
                                    {/* <ContainerWrapper>
                                        <Container borderLeft={true}>
                                            <ContainerContent>
                                            
                                            <h2 className='text-center'>"Door doorgaande feedback van onze klanten kunnen wij ons werk als topkwaliteit benoemen!"</h2>
                                            
                                            </ContainerContent>
                                        </Container>
                                    </ContainerWrapper> */}

                    <ContainerWrapper style={{animationName: "from-bottom, from-transparent", animationDuration: "1.4s"}}>
                        <Container background={false}>
                            <ContainerContent>
                                    
                            <div style={{ paddingLeft: "2%" }}>

                            {/* Partners */}
                            <PartnerCards />
                                    
                            <br/>

                                    <h1 color='red'>Ontstaan van het familiebedrijf:</h1>
                                    <br/>
                            <P>
                                Dennis Schreuder is zijn carrière in de metaal en techniek begonnen bij diverse bedrijven.
                                In 2007 heeft Dennis de stap gezet om zelfstandig verder te gaan als éénmanszaak.
                                In eerste instantie vanaf het thuisadres op de Tomatenmarkt in Zwaagdijk, Schreuder Metaal en Techniek was “geboren”.
                                Door de groei van zijn bedrijf ontstond er in 2014 de mogelijkheid om personeel aan te nemen, het machinepark uit te breiden
                                en als werkgever verder te gaan.
                            </P>
                            <P>
                                De investering in personeel en machines heeft er echter toe geleid dat de ruimte al snel te klein werd.
                                In 2016 is er dan ook voor gekozen om de intrek te nemen in het huidige pand op de Perenmarkt in Zwaagdijk.
                                Vanaf die tijd hebben de werkzaamheden zich uitgebreid naar klanten in diverse bedrijfstakken zoals de Agrisector, voedingsindustrie,
                                medische branche, verfindustrie, jachtbouw, bloemen- en zadensector, prototyping, kunststofverwerkende industrie en machinebouw.
                                En daarnaast, om ons vak verder te promoten, bieden we studenten stageplaatsen aan en dienen wij als erkend leerbedrijf.
                            </P>

                            
                        </div>
                            </ContainerContent>
                        </Container>
                    </ContainerWrapper>

                    
                </div>

            </div>
        </>
    );
};

const BCP = <div style={{ display: "flex", flexDirection: "row" }}>
{/* <img width="100%" src='images\image-dennis-01.jpg'></img> */}
<div style={{ paddingLeft: "2%" }}>
    <p>
        Dennis Schreuder is zijn carrière in de metaal en techniek begonnen bij diverse bedrijven.
        In 2007 heeft Dennis de stap gezet om zelfstandig verder te gaan als éénmanszaak.
        In eerste instantie vanaf het thuisadres op de Tomatenmarkt in Zwaagdijk, Schreuder Metaal en Techniek was “geboren”.
        Door de groei van zijn bedrijf ontstond er in 2014 de mogelijkheid om personeel aan te nemen, het machinepark uit te breiden
        en als werkgever verder te gaan.
    </p>
    <br></br>
    <p>
        De investering in personeel en machines heeft er echter toe geleid dat de ruimte al snel te klein werd.
        In 2016 is er dan ook voor gekozen om de intrek te nemen in het huidige pand op de Perenmarkt in Zwaagdijk.
        Vanaf die tijd hebben de werkzaamheden zich uitgebreid naar klanten in diverse bedrijfstakken zoals de Agrisector, voedingsindustrie,
        medische branche, verfindustrie, jachtbouw, bloemen- en zadensector, prototyping, kunststofverwerkende industrie en machinebouw.
        En daarnaast, om ons vak verder te promoten, bieden we studenten stageplaatsen aan en dienen wij als erkend leerbedrijf.
    </p>
</div>
</div>;