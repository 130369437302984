import { SOLink } from 'components/layout/interacion/socialmedia/Socialmedia';
import React from 'react';
import { Link } from 'react-router-dom';

export default function FooterSocialMedia(props) {
  return (
    <div className="social-icons">

		<SOLink place='facebook' to={`${global.config.companyInfo.socials.facebook}`}/>
		<SOLink place='youtube' to={`${global.config.companyInfo.socials.youtube}`}/>
		<SOLink place='linkedin' to={`${global.config.companyInfo.socials.linkedin}`}/>
		<SOLink place='instagram' to={`${global.config.companyInfo.socials.instagram}`}/>

		{/* <SOLink place='instagram'/> */}


		{/* <SOLink place='twitter'/> */}
		

        {/* <Link className="social-icon-link linkedin"
        to='/'
        target="_blank"
        aria-label='Linkedin'
        >
            <i className="fab fa-linkedin"></i>
        </Link> */}
    </div>
  );
}

