import React from 'react';
import HeaderSection from '../layout/pages/home/HeaderSection';
import CompanyStory from '../layout/text/CompanyStory';
import Cards from '../layout/cards/Cards';

export default function WorkerInterface () {
    return (
        <>
            <div className="page">
                <HeaderSection video={'/videos/video-1.mp4'} showInfo={true} logo={true} />

                <div className="page-content">
                    <CompanyStory />
                    
                    <Cards />
                </div>
            </div>
        </>
    );
};

