import { ImageObject } from "components/layout/media/Gallery";
import $ from 'jquery';
import HTMLReactParser from "../node_modules/html-react-parser/index";
import { Slide, Fade  } from 'react-slideshow-image';


// Home 
// let homeHeader = new ImageObject("Test");
// console.log(homeHeader);

export function importAll(require, addPath="") {
	let _return = undefined;

	_return = require.keys().reduce((acc, next) => {
		
		acc[next.replace("./", "")] = addPath + "/" + next.replace("./", "");
		
		// acc[next] = require(next).default;
		// a
		return acc;
	}, {});
	
	
	// _return = require.keys().reduce((acc, next) => {

	// 	acc[next.replace("./", "")] = String(require(next).default);

	// 	// acc[next] = require(next).default;
		
	// 	return acc;
	// }, {});

	return _return;
}

/*
*const images = importAllArray(require.context('../public/images/portfolio/layout/pages/home', true, /\.(JPG|png|jpe?g|svg)$/));
*/
export function importAllArray(require, addPath="") {
	// Exempt all in folder 'not-used': /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/
	// https://stackoverflow.com/questions/51129682/generating-regex-to-exclude-a-string-in-path
	let _return = undefined;

	_return = importAll(require, addPath);
	
	// Returns an array of values of the enumerable properties of an object
	_return = Object.values(_return);
	_return.sort();

	// console.log(_return);

	return _return;
}



// console.log(images);

export function importAll2(require) {
	let _return = undefined;

	_return = require.keys().reduce((acc, next) => {
		
		acc[next.replace("./", "")] = require(next);
		
		return acc;
	}, {});

	return _return;
}

export const Slideshow = (props) => {
	const _images = props.images;
	//style={{ height: props.mediaHeight}}
	return (
	  <div className={`slide-container ${props.className}`}>
		<Fade duration={6000} arrows={false} infinite={true}>
				{_images.map((_image, _index) => (
					<div key={_index}>
				{/* {console.log(_image)} */}
			  <img className='header-slideshow' style={{ width: '100%'}} src={_image} />
			  {/* <h2>{fadeImage.caption}</h2> */}
			</div>
		  ))}
		</Fade>
	  </div>
	)
}

function importAllImages(r) {
	return r.keys().map(r);
}
  
const images = importAllImages(require.context('../public/images/portfolio/layout/pages/home', false, /\.(png|jpe?g|svg)$/));

// console.log(images);
  
// // Function to fetch images from a directory
// async function fetchImages() {
// 	const folderPath = '../images'; // Replace with your folder path
// 	const response = await fetch(folderPath);
	
// 	if (!response.ok) {
// 	  throw new Error('Network response was not ok.');
// 	}
  
// 	const text = await response.text();
// 	const parser = new DOMParser();
// 	const htmlDocument = parser.parseFromString(text, 'text/html');
	
// 	const images = Array.from(htmlDocument.getElementsByTagName('a'))
// 	  .map(link => link.getAttribute('href'))
// 	  .filter(href => /\.(jpg|png|gif|jpeg)$/i.test(href)); // Filter by image file extensions
  
// 	return images;
//   }
  
// // Usage
// fetchImages()
// .then(images => {
// 	console.log('List of images:', images);
// 	// Do something with the array of image paths here
// })
// .catch(error => {
// 	console.error('Error fetching images:', error);
// });