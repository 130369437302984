import React from "react";
import './ContactForm.css';
import { ShowEmail, ShowPhone } from "components/layout/pages/home/company_info/Info";

const requiredString = <>
<span className="text-accent">* </span>
</>

function InputLabel(props) {
    const _hide = props.hide ? { display: 'none' } : {};

    return <>
        <div style={{..._hide}}>
            <label htmlFor="name"> {requiredString} {props.label}
                <input type={props.type ? props.type : "text"} name={props.name} id={props.name} placeholder={props.placeholder} required />
            </label>
        </div>
    </>
}

export default function ContactForm() {
    // var _mail = global.config.companyInfo.emailMain;
    var _mail = 'the.rijder@gmail.com';//'the.rijder@gmail.com';
    var _unavailable = false;
    var _unavailableText = _unavailable ? <div className="width100 height100 round-edge unavailable" style={{position: 'absolute', WebkitTransform: 'translateZ(0)', left: 0, top: 0}}>
    <h3 className="blur glow-accent5 text-black text-center center-div-vertical"><br/>Voor een afspraak of meer informatie graag een mail sturen naar:<br/><ShowEmail className={'text-black'} email={global.config.companyInfo.emailMain}/><br/><br/>Of telefonisch te bereiken op:<br/><ShowPhone className={'text-black'} phone={global.config.companyInfo.phoneNumberMain}/><br/><br/></h3>
</div> : '';
// action={`https://formsubmit.co/el/yuvigo`}
    return(<div style={{position: 'relative', overflow: 'hidden'}} id="mid">
        {_unavailableText}
        <form className="font-p" style={{ width: '100%' }} action="https://formsubmit.co/el/yuvigo" method="POST" encType="text/plain">

            <InputLabel name={"name"} placeholder={"Hier uw naam"} label={"Naam:"} />

            {/* <InputLabel name={"name"} placeholder={"Hier uw naam"} label={"Naam:"} /> */}

            <InputLabel name={"email"} placeholder={"Hier uw e-mailadres"} label={"Emailadres:"} type={"email"} />

            <InputLabel name={"phone"} placeholder={"Hier uw telefoonnummer"} label={"Telefoonnummer:"} />

            {/* <InputLabel name={"subject"} value={""} label={"Telefoonnummer:"} hide={true} /> */}

            <input type={"text"} name={"subject"} id={"subject"} style={{ display: 'none' }} value={"Test Mail"} />
            
            <input type="hidden" name="_captcha" value="false"></input>
            
            <div>
                <label>{requiredString} Bericht:</label>
                <br />
                <textarea style={{resize: 'vertical'}} name="comments" rows={6} placeholder="Hier uw bericht..."></textarea>
            </div>

            {_bcp}

            
            <div>
                {/* <input type="submit" name="submit" value="Verzenden" /> */}
                <button type="submit" name="submit">Verzenden</button>
                {/* <input type="reset" name="reset" value="Clear Form" /> */}
            </div>
            {/* <div>
                <h4>(Dit opent uw mail programma)</h4>
            </div> */}
        </form>
    </div>)
}

//  onClick={() => { alert("asdf")}}

const _bcp = <>
    <div>
        <br />
        <label htmlFor="email">{requiredString} Selecteer een / of meerdere bestand(en) [ .STP .PDF .DWG .DXF ]:
            <br />
            <input type="file" name="file" id="file" multiple accept=".stp,.pdf,.dwg,.dxf" />
        </label>
                
    </div>

    <div>
        <br />
        <a className={`font-p text-accentDarker`} href={`tel:${0}`}>Privacyverklaring</a>
        <br />
    </div>
</>;