import React from 'react';

function MissieVisie() {
    return (
        <>
            <div className="page">
                <div className='page-content'>
                    <div style={{ paddingLeft: "2%" }}>
                        <h1>Missie:</h1>
                        <p>
                            Wij maken hoogwaardig technische producten voor diverse branches.
                            Wij zien klanten als partners waarbij meedenken en het realiseren van de slimste oplossing uitgangspunt is.
                            Het leveren van topkwaliteit en het bouwen aan een duurzame relatie met onze klanten heeft voor ons hoge prioriteit.
                        </p>
                        <br></br>
                        <h1>Visie:</h1>
                        <p>
                            Ons doel is om de klant van dienst te zijn door goede afspraken te maken over intake, prijs,
                            communicatie, discretie en planning teneinde het perfecte maatwerk te leveren.
                            Dit realiseren wij met behulp van moderne technologie en creatieve
                            vakmensen die met grote toewijding hoogstaande producten maken.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MissieVisie;