import React from 'react'
import CardItem from './CardItem';
import './Cards.css';
import { P } from 'styling/Text';

var before = '';
var after = '';

const monthNames = ["Januari", "Februari", "Maart", "April", "Mei", "Juni",
  "Juli", "Augustus", "September", "Oktober", "November", "December"
];

function TitleText(string) {
  return before + string + after
}

function Card(project) {
return <>
<CardItem
                _key={"1"}
                title={TitleText(`${project.name}`)}
                src={project.image}// Image
                video={project.video}// Image
                youtube={project.youtube}// Image
                imageArray={project.imageArray}// Image
                label={project.label ? project.label : ''}
                // text={<>{project.description} {project.date.toString()}</>}
                text={<>{project.description}</>}
                date={project.date}
                path={`/factuur`}
                logo={global.config.companyMedia.logo}
                vertical={project.vertical}
            />
</>;
}

export default function CardsList(props) {
    let textTitle = props.title;
    let _cards, _years;
    let timeLine;
    let _yearElements = [], _yearElement;

    InitYears(props.projects);

    InitMonths(props.projects);

    FillMonths(props.projects);

    _cards = props.cards || {_default}

    if (props.projects) {
      // console.log(props.projects[0].owner);
        _cards = [];
        let _i = 0;
        props.projects.forEach(project => {
        _cards.push(<>
            {Card(project)}
        </>  
        );
        _i++;
        });
    }

    // Add all the events to the months
    LoopThroughYears();

    //* Initialize the years and months, dont add data to them yet

    function InitYears(_projects) {
        _years = {};
        let _i = 0;
        // Loop through each newsItem and create a key by year
        _projects.forEach(project => {

            // Add an empty object to each year
            _years[project.date.getFullYear()] = {};
        _i++;
        });
    }

    function InitMonths(_projects) {
        let _year;
        // Loop through each newsItem and create a key by year
        _projects.forEach(project => {
            _year = project.date.getFullYear();
            // console.log(_year, monthNames[project.date.getMonth()]);

            _years[_year][monthNames[project.date.getMonth()]] = [];

        });

        // console.log("Add empty months to the years", _years);
    }

    function FillMonths(_projects) {
        let _year;

        _projects.forEach(project => {
            _year = project.date.getFullYear();
            // console.log(_year, monthNames[project.date.getMonth()]);

            _years[_year][monthNames[project.date.getMonth()]].push(project);

        });

        // Loop through each key pair
        // Object.keys(_years).forEach(key => {
        //     const _year = _years[key];
        //     // console.log(key, _year);

            // Object.keys(_year).forEach(key => {
            //     const _month = _year[key];
            //     console.log(key, _month);
            // });
        // });
    }

function Month(_monthObject, _monthName, _right) {
// console.log('SIDE', _right);
    return <>
        {/* <!-- Left section, set by justify-start and sm:pr-8 --> */}
        <div className="mt-6 sm:mt-0 sm:mb-12">{/* Month */}
            <div className="flex flex-col sm:flex-row items-center">
                {_right == 1 ? 
                <>{EventRight(_monthObject, _monthName)}</>
                : 
                <>{EventLeft(_monthObject, _monthName)}</>
                }
                
                {/* Blue ball */}
                <div className="rounded-full bg-blue-500 border-white border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center"/>
            </div>
        </div>
    </>;
}



function EventLeft(_monthObject, _monthName) {
    let _events = [];
    let _subText = undefined;

    Object.keys(_monthObject).forEach(key => {
        const _event = _monthObject[key];

        if (_event.subText) {
            _subText = _event.subText;
        }

        _events.push(<>
        <div className="sm:pr-8 from-left-play">{/* Event */}
                {Card(_event)}
            <br/>
        </div>
        
        
        </>);

        // console.log(key, _event);
    });

    return <>
        <div className="flex flex-col justify-start w-full  sm:w-1/2 mr-auto items-center">{/* What happened in the month */}
            {_events}
        </div>

        <div className="absolute justify-end w-full mx-auto items-center pointer-events-none">{/* Month Text */}
            <div className={`w-full sm:w-1/2 sm:pl-8 float-right sm:static relative ${_subText ? "bottom-20" : "bottom-14"}`}>
                <div className="p-4 rounded text-left from-right-play">
                    <h2>{_monthName}</h2>
                    {_subText ? <h4>{_subText}</h4> : ''}
                </div>
            </div>
        </div>
    </>
};

function EventRight(_monthObject, _monthName) {
    let _events = [];
    let _subText = undefined;

    Object.keys(_monthObject).forEach(key => {
        const _event = _monthObject[key];
        
        if (_event.subText) {
            _subText = _event._subText;
        }

        _events.push(<>
        <div className="sm:pl-8 from-right-play">{/* Event */}
                {Card(_event)}
            <br/>
        </div>
        
        
        </>);

        // console.log(key, _event);
    });

    return <>
        <div className="flex flex-col justify-end w-full  sm:w-1/2 ml-auto items-center">{/* What happened in the month */}
            {_events}
        </div>

        <div className="absolute justify-end w-full mx-auto items-center pointer-events-none">{/* Month Text */}
            <div className={`w-full sm:w-1/2 sm:pr-8 sm:static relative  ${_subText ? "bottom-20" : "bottom-14"}`}>
                <div className="p-4 rounded sm:text-right from-left-play">
                    <h2>{_monthName}</h2>
                    {_subText ? <h4>{_subText}</h4> : ''}
                </div>
            </div>
        </div>
    </>
};

const _right = <>
<div className="flex justify-end w-full mx-auto items-center">{/* What happened in the month */}
    <div className="w-full sm:w-1/2 sm:pl-8">{/* Event */}
        {/* {_cards[2]} */}
    </div>
</div>

<div className="absolute justify-end w-full mx-auto items-center">{/* Month Text */}
    <div className="w-full sm:w-1/2 sm:pr-8">
        <div className="p-4 bg-white rounded text-right">
            <h2>Augustus</h2>
        </div>
    </div>
</div>
</>;

        
    function LoopThroughYears() {

        let _right = 0;
        // Loop through each key pair (slice and reverse order)
        Object.keys(_years).slice().reverse().forEach(_yearKey => {
            const _year = _years[_yearKey];
            _yearElement = <></>;
            // console.log(key, _year);

            _yearElement = <>
                {/* <!-- Vertical bar running through middle --> */}
                <h1 className='text-center bg-white sm:bg-transparent'>{_yearKey}</h1>
                {/* Months */}
                {/* <div className="hidden sm:block w-1 bg-blue-300 absolute h-full left-1/2 transform -translate-x-1/2"></div> */}
                <div className="w-1 bg-blue-300 absolute sm:h-full h-2/3 left-1/2 transform -translate-x-1/2"></div>
                <br/>
            </>

            let _months = [];
            Object.keys(_year).slice().reverse().forEach(key => {
                const _month = _year[key];

                // Push the month object
                _months.push(<>
                    {Month(_month, key, _right)}
                </>);

                // Add 1 to the _right variable and loop the value if it is above 1
                _right++;
                _right %= 2;
            });

            // Add months
            _yearElement = <>{_yearElement} {_months}</>;
    
            _yearElements.push(<>
                    <div className="px-2 relative text-gray-700 antialiased">{/* Years */}
                        {_yearElement}
                    </div>
                {/* <div className="hidden sm:block h-1 bg-blue-300 absolute w-20 left-1/2 transform -translate-x-1/2"></div> */}
                <div className="h-1 bg-blue-300 absolute w-20 left-1/2 transform -translate-x-1/2"></div>
                
            </>);
        });


        // Object.keys(_year).forEach(key => {
        //         const _month = _year[key];
        //         console.log(key, _month);
        //     });
    }


    return (<><div className="py-3 sm:max-w-3xl sm:mx-auto w-full px-2 sm:px-2">
            {_yearElements}
        </div>
        
    </>);
  }

const _textbcp = <>
<div className="py-3 sm:max-w-3xl sm:mx-auto w-full px-2 sm:px-0">
            <div className="relative text-gray-700 antialiased">{/* Years */}

                {/* <!-- Vertical bar running through middle --> */}
                <h1 className='text-center'>2024</h1>
                <div className="hidden sm:block w-1 bg-blue-300 absolute h-full left-1/2 transform -translate-x-1/2"></div>{/* Months */}
                <br/>
                {/* <!-- Left section, set by justify-start and sm:pr-8 --> */}
                <div className="mt-6 sm:mt-0 sm:mb-12">{/* Month */}
                    <div className="flex flex-col sm:flex-row items-center">
                    
                        <div className="flex flex-col justify-start w-full  sm:w-1/2 mr-auto items-center">{/* What happened in the month */}
                            <div className="sm:pr-8">{/* Event */}
                                {/* {_cards[0]} */}
                            <br/>
                            </div>
                            <div className="sm:pr-8">{/* Event */}
                            <br/>
                                {/* {_cards[1]} */}
                            </div>
                        </div>

                        <div className="absolute justify-end w-full mx-auto items-center">{/* Month Text */}
                            <div className="w-full sm:w-1/2 sm:pl-8 float-right">
                                <div className="p-4 bg-white rounded text-left">
                                    <h2>September</h2>
                                </div>
                            </div>
                        </div>

                        <div className="rounded-full bg-blue-500 border-white border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center"/>

                    </div>
                </div>
                {/* --- */}

                {/* <!-- Right section, set by justify-end and sm:pl-8 --> */}
                <div className="mt-6 sm:mt-0 sm:mb-12">{/* Month */}
                    <div className="flex flex-col sm:flex-row items-center">

                        <div className="flex justify-end w-full mx-auto items-center">{/* What happened in the month */}
                            <div className="w-full sm:w-1/2 sm:pl-8">{/* Event */}
                                {/* {_cards[2]} */}
                            </div>
                        </div>

                        <div className="absolute justify-end w-full mx-auto items-center">{/* Month Text */}
                            <div className="w-full sm:w-1/2 sm:pr-8">
                                <div className="p-4 bg-white rounded text-right">
                                    <h2>Augustus</h2>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="rounded-full bg-blue-500 border-white border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center"/>

                    </div>
                </div>
                {/* --- */}
                
            </div>
        </div>
</>;

function _bcpLoop() {
let timeLine, _years;
function Month(_a, _b, _c) {};
function LoopThroughYears() {

        let _right = 0;
        // Loop through each key pair
        Object.keys(_years).forEach(_yearKey => {
            const _year = _years[_yearKey];
            let _yearElement = <></>;
            // console.log(key, _year);

            timeLine = <>{timeLine} 
                {/* <!-- Vertical bar running through middle --> */}
                <h1 className='text-center'>{_yearKey}</h1>
                <div className="hidden sm:block w-1 bg-blue-300 absolute h-full left-1/2 transform -translate-x-1/2"></div>{/* Months */}
                <br/>
            </>

            let _months = [];
            Object.keys(_year).forEach(key => {
                const _month = _year[key];

                // Push the month object
                _months.push(<>
                    {Month(_month, key, _right)}
                </>);

                // Add 1 to the _right variable and loop the value if it is above 1
                _right++;
                _right %= 2;
            });

            // Add months
            timeLine = <>{timeLine} {_months}</>;
    // !Here goes something wrong
            timeLine = <>
                <div className="px-2 relative text-gray-700 antialiased">{/* Years */}
                    {timeLine}
                </div>
                <div className="hidden sm:block h-1 bg-blue-300 absolute w-20 left-1/2 transform -translate-x-1/2"></div>
            </>;
            });


        // Object.keys(_year).forEach(key => {
        //         const _month = _year[key];
        //         console.log(key, _month);
        //     });
    }


    return (<><div className="py-3 sm:max-w-3xl sm:mx-auto w-full px-2 sm:px-2">
            {timeLine}
        </div>
        
    </>);
}

{/* <div className="rounded-full bg-blue-500 border-white border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
    </svg>
</div> */}

// export  function CardsListbcp(props) {
//     let textTitle = props.title;
//     let _cards;

//     _cards = props.cards || {_default}

//     if (props.projects) {
//       // console.log(props.projects[0].owner);
//       _cards = [];
//       let _i = 0;
//       props.projects.forEach(project => {
//         _cards.push(
//           <CardItem
//               _key={String(_i)}
//               title={TitleText(`${project.name}`)}
//               src={`${project.image}`}// Image
//               imageArray={project.imageArray}// Image
//               label={project.label ? project.label : ''}
//               text={project.description}
//               date={project.date}
//               path={`/factuur`}
//               logo={global.config.companyMedia.logo}
//               vertical={project.vertical}
//             />
//         );
//         _i++;
//       });
//     }



//     return (
//       <div className='cards'>
//         {textTitle ? <h1>{textTitle}</h1> : ''}
//         <div className='cards_container'>
//           <div className='cards_wrapper'>
//             <div className='cards_items'>
//               {_cards}
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }


const _default = <>
    <CardItem
                key={1}
                title={TitleText('Laswerk')}
                src={`/images/portfolio/custom-laswerk/custom-laswerk-as-01.jpeg`}
                label='Product'
                text={window.basicText}
                date='asd'
                path='/factuur'
              />
              <CardItem
              key={2}
                title={TitleText('Motor Onderdelen')}
                src={`/images/portfolio/custom-motor-onderdelen/custom-motor-onderdelen-project-01.jpeg`}
                label='Eigen Projecten'
                text={window.basicText}
                date='Product'
                path='/portfolio'
              />
              <CardItem
              key={3}
                title={TitleText('Race Onderdelen')}
                src={`/images/portfolio/custom-race-onderdelen/custom-race-onderdelen-handrem.jpeg`}
                label='Eigen Projecten'
                text={window.basicText}
                date='Product'
                path='/portfolio'
              />
              <CardItem
              key={4}
                title={TitleText('Tuin Decoratie')}
                src={`/images/portfolio/custom-tuin-decoratie/custom-tuin-decoratie-rozenboog-01.jpeg`}
                label='Product'
                text={window.basicText}
                date='asd'
                path='/factuur'
                zoom={150}
              />
    </>;