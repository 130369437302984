import React from 'react'
import CardItem from './CardItem';
import './Cards.css';

var before = 'Custom ';
var after = '';

function TitleText(string) {
  return before + string + after
}

export default function Cards(props) {
    let textTitle = props.title;

    const _cards = props.cards || <>
    <CardItem
                key={1}
                title={TitleText('Laswerk')}
                src={`/images/portfolio/custom-laswerk/custom-laswerk-as-01.jpeg`}
                label='Product'
                text={window.basicText}
                date='asd'
                path='/factuur'
              />
              <CardItem
              key={2}
                title={TitleText('Motor Onderdelen')}
                src={`/images/portfolio/custom-motor-onderdelen/custom-motor-onderdelen-project-01.jpeg`}
                label='Eigen Projecten'
                text={window.basicText}
                date='Product'
                path='/portfolio'
              />
              <CardItem
              key={3}
                title={TitleText('Race Onderdelen')}
                src={`/images/portfolio/custom-race-onderdelen/custom-race-onderdelen-handrem.jpeg`}
                label='Eigen Projecten'
                text={window.basicText}
                date='Product'
                path='/portfolio'
              />
              <CardItem
              key={4}
                title={TitleText('Tuin Decoratie')}
                src={`/images/portfolio/custom-tuin-decoratie/custom-tuin-decoratie-rozenboog-01.jpeg`}
                label='Product'
                text={window.basicText}
                date='asd'
                path='/factuur'
                zoom={150}
              />
    </>;


    return (
      <div className='cards'>
        {textTitle ? <h1>{textTitle}</h1> : ''}
        <div className='cards_container'>
          <div className='cards_wrapper'>
            <div className='cards_items'>
              {_cards}
            </div>
          </div>
        </div>
      </div>
    );
  }
